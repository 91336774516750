<!-- <bp-splash-wrapper> -->
    <!-- <div class="main-content"> -->
        <aim-loading *ngIf="!signingIn && !error" title='Opening application...'></aim-loading>
        <aim-loading *ngIf="signingIn" title='Signing in...'></aim-loading>

        <div *ngIf="error" class="warn">
            <h1>Login Failed</h1>
            <p>{{error}}</p>

            <div *ngIf="!sessionExpiredMode" class="buttons">
                <aim-button (click)="openHome.emit()" [text]="'Go to Home Page'" [icon]="'fa-home'"></aim-button>
            </div>
        </div>
    <!-- </div>
</bp-splash-wrapper> -->
