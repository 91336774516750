import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ExamInfo } from 'app/model/entities/exam-summary';
import { ExamSessionRequest } from '../model/exam-session-request';
import { Exam } from 'app/model/entities/exam';
import { EventAggregatorService } from './event-aggregator.service';
import { ExamSession } from 'app/model/entities/exam-session';
import { CompleteExamSessionRequest, ExamElapsedSecondsRequest } from '../model';
import { AuthService } from './auth.service';


@Injectable()
export class BackEndService {
    private url = '/examapi/v1/';
    private options = { headers: new HttpHeaders({'Content-Type': 'application/json'}) };

    constructor(private http: HttpClient, private eas: EventAggregatorService, private authService: AuthService) {
    }

    getErrorMessage(e: any) {
        return e && e.error ? e.error : '';
    }

    async getSsoUrl(): Promise<string> {
        return await this.http.get<string>(`${this.url}ssourl`, this.options).toPromise();
    }

    async loadExamSummaries(): Promise<ExamInfo[]> {
        console.log('[BackEndService] loadExamSummaries');
        return this.http.get(`${this.url}summary`, this.options)
            .toPromise()
            .then((response: any) => {
                console.log('[BackEndService] Exam Summaries Loaded!');
                const summaries = response.summaries.map((x: any) => <ExamInfo>{
                    examId: x.examId,
                    takenTimes: x.takenTimes,
                    correctAnswers: x.correctAnswers,
                    totalAnswers: x.totalAnswers,
                    elapsedSeconds: x.elapsedSeconds,
                    completedOn: x.completedOn ? new Date(x.completedOn) : null,
                    startedOn: x.startedOn ? new Date(x.startedOn) : null
                });
                this.authService.userLevel = response.certificationLevel;
                return summaries;
            })
            .catch(e => {
                console.warn('[BackEndService] Unable to load Exam Summaries');
                return Promise.reject(e);
            });
    }

    async loadExam(body: ExamSessionRequest): Promise<Exam> {
        console.log('[BackEndService] loadExam');
        this.eas.publish('loading', true);
        return this.http.post(`${this.url}session`, body, this.options)
            .toPromise()
            .then((response: any) => {
                console.log('[BackEndService] Exam Loaded!');
                const exam = <Exam>response;
                exam.session = ExamSession.assign(exam.session);
                this.eas.publish('loading', false);
                return exam;
            })
            .catch(e => {
                console.warn('[BackEndService] Unable to load Exam');
                this.eas.publish('loading', false);
                return Promise.reject(e);
            });
    }

    async saveAnswer(body: ExamSessionRequest): Promise<void> {
        console.log('[BackEndService] saveAnswer');
        return this.http.post(`${this.url}session/answer`, body, this.options)
            .toPromise()
            .then((response: any) => {
                console.log('[BackEndService] Answer Saved!');
            })
            .catch(e => {
                console.warn('[BackEndService] Unable to save Answer');
                return new Promise((resolve, reject) => setTimeout(() => reject(e), 1500));
            });
    }

    async completeExam(body: CompleteExamSessionRequest): Promise<void> {
        console.log('[BackEndService] completeExam');
        return this.http.post(`${this.url}session/complete`, body, this.options)
            .toPromise()
            .then((response: any) => {
                console.log('[BackEndService] Exam Completed!');
            })
            .catch(e => {
                console.warn('[BackEndService] Unable to complete Exam');
                return Promise.reject(e);
            });
    }

    async examElapsed(body: ExamElapsedSecondsRequest): Promise<void> {
        console.log('[BackEndService] examElapsed');
        return this.http.post(`${this.url}session/time`, body, this.options)
            .toPromise()
            .then((response: any) => {
                console.log('[BackEndService] Exam elapsed was changed!');
            })
            .catch(e => {
                console.warn('[BackEndService] Unable to change Exam elapsed');
                return Promise.reject(e);
            });
    }

}
