import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/services';

@Component({
    selector: 'exam-header',
    templateUrl: './exam-header.component.html',
    styleUrls: ['./exam-header.component.scss']
})
export class ExamHeaderComponent implements OnInit {

    @Input() secondStyle: boolean;
    @Input() linkAction: string;
    @Input() titleAsLink: boolean;
    @Output() linkClick = new EventEmitter();
    showUserMenu = false;

    constructor(public  authService: AuthService, private router: Router) { }

    ngOnInit(): void {
    }


    toggleProfile(e: MouseEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.showUserMenu = !this.showUserMenu;

    }

    async logout() {
        console.log('[ExamHeaderComponent] logout');
        await this.authService.logout();

        await this.router.navigate(['/login']);
        location.reload();
    }
}
