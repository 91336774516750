import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExamQuestionAnswer } from 'app/model/entities/exam';
import { AnswerStatus } from 'app/shared/models/answer-type.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'exam-answer',
    templateUrl: './answer.component.html',
    styleUrls: ['./answer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnswerComponent implements OnInit {

    private _sessionAnswerId: number;
    @Input() answer: ExamQuestionAnswer;
    @Input() resultView: boolean;
    @Input() save$: Observable<number>;
    @Input() saving: boolean;
    @Output() select = new EventEmitter<number>();
    status: AnswerStatus;
    AnswerStatus = AnswerStatus;

    @Input() get sessionAnswerId(): number {
        return this._sessionAnswerId;
    }
    set sessionAnswerId(id: number) {
        this._sessionAnswerId = id;
        this.init();
    }

    constructor() { }

    ngOnInit(): void {
        this.save$.subscribe((id: number) => {
            if (this.answer.id === id) {
                this.select.emit(id);
            }
            if (id === -1) {
                this.status = AnswerStatus.NotSelected;
            }
        });
    }

    init() {
        if (this.resultView) {
            if (this.answer.correct) {
                this.status = AnswerStatus.Selected;
            } else if (this.answer.id === this.sessionAnswerId) {
                this.status = AnswerStatus.InCorrect;
            } else {
                this.status = AnswerStatus.NotSelected;
            }
        } else {
            this.status = this.answer.id === this.sessionAnswerId ? AnswerStatus.Selected : AnswerStatus.NotSelected;
        }
    }

    save() {
        if (!this.resultView && !this.saving && this.sessionAnswerId !== this.answer.id) {
            this.select.emit(this.answer.id);
            this.status = AnswerStatus.Active;
        }
    }

}
