import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-profile-menu',
    templateUrl: './profile-menu.component.html',
    styleUrls: ['./profile-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMenuComponent implements OnInit {
    @Input() name = '';
    @Input() email = '';
    @Output() logout = new EventEmitter();
    @Output() clickOutside = new EventEmitter<MouseEvent>();

    constructor() { }

    ngOnInit() {
    }
}
