import { ExamSummary } from './exam-summary';

export class ExamHelper {
    public static getExamName(examId: number, blueBelt: boolean): string {
        switch (examId) {
            case 1:
                return `${blueBelt ? 'Advanced' : ''} Market Segmentation`;
            case 2:
                return `${blueBelt ? 'Advanced' : ''} Discovery Preparation`;
            case 3:
                return `${blueBelt ? 'Advanced' : ''} Discovery Interviews`;
            case 4:
                return `${blueBelt ? 'Advanced' : ''} Preference Interviews`;
            case 5:
                return 'Blueprinter Software';
            case 6:
                return 'Blueprinting Methodology';
            case 7:
                return 'Rest of Blueprinting';

            default:
                return `${blueBelt ? 'Advanced' : ''} 'Market Segmentation`;
        }
    }

    public static retakenIn(completedOn: Date | null): number  {
        if (!completedOn) {
            return -1;
        }
        const now = new Date();
        const hours = (+now - +completedOn) / (1000 * 60 * 60);
        return Math.min(24, Math.ceil(24 - hours));
    }

}
