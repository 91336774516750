export class ExamSession {

    sessionQuestions: ExamSessionQuestion[];

    static assign(s: ExamSession): ExamSession {
        const t = new ExamSession(s.id,
            s.completedOn ? new Date(s.completedOn) : undefined, s.elapsedSeconds, s.correctAnswers);
        t.sessionQuestions = s.sessionQuestions;
        return t;
    }

    get elapsedMinutes(): number {
        return Math.floor(this.elapsedSeconds / 60);
    }

    get timeRemaining(): number {
        return 60 - this.elapsedMinutes;
    }

    constructor(public id: number, public completedOn?: Date,
        public elapsedSeconds: number = 0, public correctAnswers?: number, level: number = 0) { }
}
export class ExamSessionQuestion {
    constructor(public index: number, public questionId: number, public answerId: number = 0) { }
}
