import { Injectable } from '@angular/core';
import { pluck, share, filter, delay } from 'rxjs/operators';
import { Observable ,  Subject } from 'rxjs';

class Event {
    type: string;
    data: any;
}

@Injectable()
export class EventAggregatorService {
    private subject: Subject<Event>;

    constructor() {
        this.subject = new Subject();

        this.listen('init').subscribe(() => this.init());
    }

    private init() {
        console.log('[EventAggregatorService] init');
    }

    public publish(type: string, data: any = undefined) {
        console.log(`[EventAggregatorService] publish ${type}`);
        this.subject.next({type, data});
    }

    public listen(type: string): Observable<any> {
        return this.subject.pipe(
            filter(x => x.type === type),
            pluck('data'),
            share());
    }
}
