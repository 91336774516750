import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import * as c from './components';
import { RouterModule } from '@angular/router';
import { CoreModule } from 'app/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        CoreModule
    ],
    declarations: [
        c.ExamHeaderComponent,
        c.ExamHomeHeaderComponent,
        c.SummaryComponent,
        c.PagerComponent,
        c.ButtonComponent,
        c.InfoHeaderComponent,
        c.ErrorModalComponent
    ],
    providers: [

    ],
    exports: [
        c.ExamHeaderComponent,
        c.ExamHomeHeaderComponent,
        c.SummaryComponent,
        c.PagerComponent,
        c.ButtonComponent,
        c.InfoHeaderComponent,
        c.ErrorModalComponent
    ]
})
export class SharedModule { }
