import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { AuthService, EventAggregatorService } from '../services';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService, private router: Router, private eas: EventAggregatorService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request = req;
        this.authService.licenseExpired = false;
        const token = this.authService.accessToken;
        if (token) {
            let headers = request.headers.set('Authorization', 'Bearer ' + token);
            const company = this.authService.company; // let's add Company Id if we are dealing with a coach
            if (company && !headers.get('CompanyId')) {
                console.log('[ExtendedHttpService] append company id');
                headers = headers.set('CompanyId', company.id.toString());
            }
            request = request.clone({ headers });
        }

        return next.handle(request).pipe(
            catchError(event => {
                console.warn('[AuthInterceptor] catchError', event);
                if (event instanceof HttpErrorResponse) {
                    if (event.status === 400) {
                        if (event.error && event.error.Message === 'License Expired') {
                            this.authService.licenseExpired = true;
                        }
                    } else if (event.status === 401) {
                        this.authService.sessionExpired = true;
                        this.eas.publish('sessionExpired');
                        this.router.navigate(['login']);

                    } else {
                    }
                }
                return throwError(event);
            })
        );
    }
}
