import { Injectable, Injector } from '@angular/core';
import { StateProviderService } from './state-provider.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventAggregatorService } from './event-aggregator.service';
import { CertificationLevel } from 'app/model/entities/enums';

interface Token {
    token: string;
    email: string;
    id: number;
    firstName: string;
    lastName: string;
    roleId: number;
    certificationLevel: CertificationLevel;
}

interface Company {
    id: number;
    name: string;
    key: string;
}

@Injectable()
export class AuthService {
    private tokenKey = 'token';
    private _token?: Token;
    private companyKey = 'company';
    private _company?: Company;
    public licenseExpired: boolean;
    public sessionExpired: boolean;

    get userId(): number {
        return this._token ? this._token.id : 0;
    }

    get userName(): string {
        return (this._token) ? this._token.email : '';
    }

    get fullName(): string {
        return (this._token) ? `${this._token.firstName} ${this._token.lastName}` : '';
    }

    get userLevel(): number {
        return (this._token) ? this._token.certificationLevel : 0;
    }
    set userLevel(l: number) {
        if (this._token) {
            this._token.certificationLevel = l;
            this.setToken(this._token);
        }
    }

    get companyServiceKey(): string {
        const c = this.company;
        return (c && c.key) ? c.key : '';
    }

    get company(): Company | undefined {
        return this._company;
    }

    get userRole(): number {
        return this._token ? this._token.roleId : 0;
    }

    get accessToken(): string {
        if (this._token) {
            return this._token.token;
        }
        console.log('[AuthService] Token not defined!');
        return '';
    }

    get blueBelt(): boolean {
        return Number(this._token?.certificationLevel) > 0;
    }

    constructor(private injector: Injector
        , private stateProviderService: StateProviderService
        , private eventAggregatorService: EventAggregatorService) {
        stateProviderService.get(this.tokenKey, x => this._token = x);
        stateProviderService.get(this.companyKey, x => this._company = x);
        this.eventAggregatorService.listen('init').subscribe(() => this.init());
    }

    public reloadToken() {
        if (this.sessionExpired) {
            this.stateProviderService.get(this.tokenKey, x => this._token = x);
            this.eventAggregatorService.publish('reloadToken');
            this.sessionExpired = false;
        }
    }

    private init() {
        console.log('[AuthService] init');
        if (this._token) {
            this.eventAggregatorService.publish('login');
        }
    }


    public async ssoLogin(ssoToken: string): Promise<any> {
        console.groupCollapsed(`[AuthService] signing using SSO token ${ssoToken}`);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const options = { headers: headers };
        const http = this.injector.get(HttpClient);
        return http.post(`/examapi/v1/authenticate`, `{"token": "${ssoToken}"}`, options)
            .toPromise()
            .then(result => {
                const token = <Token>result;
                // if (this.userName && this.userName !== token.email) {
                //     throw Error(`You are already logged in as ${this.userName}.
                //         Please logout first to be able to login as ${token.email}`);
                // }
                this.setToken(token);
                console.log(`[AuthService] signed in ${token.token}`);
                console.groupEnd();
                this.sessionExpired = false;
            }, (e: any) => {
                console.log(`[AuthService] sign in attempt failed ${e.message}`);
                console.groupEnd();
                return Promise.reject(e);
            });
    }

    private setToken(token: Token) {
        this._token = token;
        this.stateProviderService.set(this.tokenKey, token);
    }

    public logout() {
        this.eventAggregatorService.publish('logout');
        this.reset();
    }

    public reset() {
        this.stateProviderService.remove(this.tokenKey);
        this._token = undefined;
        this._company = undefined;
    }

    public select(company: Company) {
        this._company = company;
        this.stateProviderService.set(this.companyKey, company);
    }
}
