import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonType } from 'app/shared/models/button-type.model';

@Component({
    selector: 'exam-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

    @Input() headerText: string;
    @Input() bodyText: string;
    @Input() warning: boolean;
    @Input() cancelBtn: boolean;
    @Input() customBtnType: ButtonType;
    @Input() customBtnText: string;
    @Input() hideClose: boolean;
    @Output() action = new EventEmitter();
    @Output() cancel = new EventEmitter();
    ButtonType = ButtonType;
    constructor() { }

    ngOnInit(): void {
    }

}
