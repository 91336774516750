import { ExamStatus } from './enums';

export class ExamInfo {
    constructor(
        public examId: number,
        public takenTimes: number,
        public correctAnswers: number,
        public totalAnswers: number,
        public elapsedSeconds: number,
        public startedOn: Date | null,
        public completedOn: Date | null
    ) { }
}

export class ExamSummary {

    constructor(
        public examId: number,
        public status: ExamStatus,
        public takenTimes: number = 0,
        public correctAnswers: number = 0,
        public totalAnswers: number = 0,
        public score: number,
        public timeRemaining: number = 0,
        public timeToComplete: number | null = null,
        public numberOfQuestions: number = 0,
        public firstNotAnsweredQuestionIndex: number | null = null,
        public completedOn: Date | null = null,
        public lastStartedOn: Date | null = null,
        public canBeRetaken = false
    ) { }
}
