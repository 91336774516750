<exam-home-header [blueBelt]="false"></exam-home-header>

    <div *ngIf="error" class="error" qa="login-error">
        <h1>Unable to connect to the server. </h1>
        <p>Please make sure you are connected to Internet.</p>
    </div>
    <aim-loading *ngIf="!url && !error"></aim-loading>
    <aim-login *ngIf="url && !authService.sessionExpired" (login)="login()"></aim-login>

    <div class="footer">
        © 2020&nbsp; <a qa="f-aim" target="_blank" href='https://theaiminstitute.com/'>The AIM
            Institute</a>&nbsp;<b>|</b>&nbsp;<a qa="f-cc" target="_blank" href='https://www.blueprintingcenter.com/'>Blueprinting Center</a>&nbsp;<b>|</b>&nbsp;<a qa="f-help"
            target="_blank" href='https://support.theaiminstitute.com/'>BlueHelp</a>
    </div>

