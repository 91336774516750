import { EventEmitter, Component, Output } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    @Output() login = new EventEmitter();

}
