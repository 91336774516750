<exam-header [secondStyle]="true" [titleAsLink]="true" [linkAction]="'Close'" (linkClick)="close()"></exam-header>
<exam-info-header
    [examName]="examName"
    [examType]="ExamType.Practitioner"
    [examId]="exam.id"
    [completedOn]="exam.session.completedOn"></exam-info-header>

<div class="container" *ngIf="exam">
    <div class="panel">
        <div class="title" qa="result">{{passed ? 'Congratulations!' : 'Final Score'}}</div>
        <div class="summary">
            <div>
                <svg *ngIf="passed" width="233.14" height="233.14" viewBox="0 0 233.14 233.14" qa="img-pass">
                    <defs>
                        <style>
                            .cong-a { fill: #f37a20; stroke: #fff; stroke-width: 2px; }
                            .cong-b, .cong-c { fill: #fff; }
                            .cong-b { font-size: 65px; font-family: Roboto-Regular; letter-spacing: -0.05em; }
                            .cong-d {  filter: url(#a); }
                        </style>
                        <filter id="a" x="0" y="0" width="233.143" height="233.141" filterUnits="userSpaceOnUse">
                            <feOffset dy="2" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="6" result="b" />
                            <feFlood flood-opacity="0.282" />
                            <feComposite operator="in" in2="b" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="translate(-130.917 -279.916)">
                        <g class="cong-d" transform="matrix(1, 0, 0, 1, 130.92, 279.92)">
                            <path class="cong-a"
                                d="M174.692,97.481l17.516-17.143a9.075,9.075,0,0,0-4.062-15.365l-23.864-6.091,6.727-23.622a9.228,9.228,0,0,0-11.3-11.3L136.1,30.689,130.009,6.817a9.139,9.139,0,0,0-15.36-4.063L97.512,20.405,80.375,2.754C75.564-2.332,66.645,0,65.015,6.817L58.927,30.689,35.313,23.959a9.228,9.228,0,0,0-11.3,11.3l6.727,23.622L6.878,64.974A9.074,9.074,0,0,0,2.816,80.339L20.332,97.481,2.816,114.624a9.075,9.075,0,0,0,4.062,15.365l23.864,6.091L24.014,159.7a9.228,9.228,0,0,0,11.3,11.3l23.614-6.729,6.088,23.872a9.057,9.057,0,0,0,15.36,4.064l17.137-17.522,17.137,17.522a9.094,9.094,0,0,0,15.36-4.064l6.088-23.872L159.711,171a9.228,9.228,0,0,0,11.3-11.3l-6.727-23.622,23.864-6.091a9.075,9.075,0,0,0,4.062-15.365L174.692,97.481Z"
                                transform="translate(19.06 17.06)" />
                        </g><text class="cong-b" [attr.transform]="'translate(' + (percent < 10 ? '205.54' : (percent < 100 ? '185.54' : '170.54')) + ' 430.82)'">
                            <tspan x="0" y="0" qa="score">{{percent}}%</tspan>
                        </text>
                        <g transform="translate(199.269 336.01)">
                            <g transform="translate(0)">
                                <path class="cong-c"
                                    d="M369.689,387.136l5.017,4.914-1.656,1.693-12.508-12.25,4.686-4.785a6.169,6.169,0,0,1,4.079-2.1,5.427,5.427,0,0,1,5.437,5.325,6.213,6.213,0,0,1-2.025,4.116Zm-1.323-1.295,3.031-3.092a3.178,3.178,0,0,0,.068-4.881,3.482,3.482,0,0,0-2.354-1.067,3.405,3.405,0,0,0-2.55,1.213l-3.029,3.092Z"
                                    transform="translate(-360.542 -359.476)" />
                                <path class="cong-c"
                                    d="M379,380.781l-5.78,3.166.8,4.7-2.12,1.161-2.877-18.385,1.781-.975,13.832,12.379-2.118,1.161Zm-6.131,1.1,4.586-2.514L371.6,374l-.062.033Z"
                                    transform="translate(-351.626 -363.836)" />
                                <path class="cong-c"
                                    d="M386.992,379.97a2.642,2.642,0,0,0-1.188-1.818,7.166,7.166,0,0,0-3.2-.827,9.973,9.973,0,0,1-4.44-1.192,4.076,4.076,0,0,1-2.037-2.922,4.321,4.321,0,0,1,.989-3.656A6.248,6.248,0,0,1,381,367.476a6.11,6.11,0,0,1,4.611.778,4.754,4.754,0,0,1,2.208,3.328l-.025.076-2.224.4a3.232,3.232,0,0,0-1.455-2.247,3.732,3.732,0,0,0-2.791-.494,3.643,3.643,0,0,0-2.364,1.235,2.686,2.686,0,0,0-.5,2.214,2.326,2.326,0,0,0,1.284,1.707,8.906,8.906,0,0,0,3.33.774,8.934,8.934,0,0,1,4.317,1.287,4.4,4.4,0,0,1,1.935,3,4.087,4.087,0,0,1-1.046,3.64,6.9,6.9,0,0,1-4.057,2.023,7.382,7.382,0,0,1-4.656-.616,4.323,4.323,0,0,1-2.516-3.414l.01-.076,2.226-.392a2.945,2.945,0,0,0,1.674,2.286,4.733,4.733,0,0,0,2.938.39,4.251,4.251,0,0,0,2.491-1.211A2.463,2.463,0,0,0,386.992,379.97Z"
                                    transform="translate(-344.229 -367.108)" />
                                <path class="cong-c"
                                    d="M393.821,381.057a2.637,2.637,0,0,0-.562-2.1,7.116,7.116,0,0,0-2.782-1.781,10.007,10.007,0,0,1-3.847-2.52,4.081,4.081,0,0,1-1.024-3.412,4.318,4.318,0,0,1,2.081-3.164,6.236,6.236,0,0,1,4.336-.765,6.108,6.108,0,0,1,4.141,2.179,4.739,4.739,0,0,1,1.057,3.849l-.045.066-2.237-.32a3.238,3.238,0,0,0-.681-2.587,3.748,3.748,0,0,0-2.5-1.342,3.653,3.653,0,0,0-2.63.439,2.688,2.688,0,0,0-1.17,1.945,2.328,2.328,0,0,0,.687,2.023,8.938,8.938,0,0,0,2.922,1.773,8.9,8.9,0,0,1,3.7,2.571,4.4,4.4,0,0,1,.9,3.455,4.091,4.091,0,0,1-2.132,3.131,6.9,6.9,0,0,1-4.485.659,7.39,7.39,0,0,1-4.231-2.038A4.324,4.324,0,0,1,384,379.093l.035-.068,2.237.32a2.941,2.941,0,0,0,.878,2.694,4.743,4.743,0,0,0,2.669,1.287,4.262,4.262,0,0,0,2.743-.371A2.461,2.461,0,0,0,393.821,381.057Z"
                                    transform="translate(-335.949 -367.229)" />
                                <path class="cong-c"
                                    d="M402.692,381.582l-6.482-3.113-2.711,5.647,7.586,3.642-.8,1.67-9.724-4.666,7.576-15.783,9.6,4.611-.806,1.678-7.469-3.583-2.45,5.105L403.5,379.9Z"
                                    transform="translate(-328.964 -365.388)" />
                                <path class="cong-c"
                                    d="M395.962,385.834l12.174-12.582,3.956,3.831a7.2,7.2,0,0,1,.2,10.717l-1.664,1.719a7.179,7.179,0,0,1-10.709.144Zm12.578-9.595-9.588,9.911,2.255,2.181a4.826,4.826,0,0,0,3.864,1.514,6,6,0,0,0,3.858-1.974l1.68-1.736a5.958,5.958,0,0,0,1.826-3.9,4.823,4.823,0,0,0-1.639-3.812Z"
                                    transform="translate(-323.284 -360.893)" />
                            </g>
                        </g>
                    </g>
                </svg>
                <svg *ngIf="!passed" width="233.19" height="233.19" viewBox="0 0 233.19 233.19" qa="img-fail">
                    <defs>
                        <style>
                            .fail-a { stroke: #fff; stroke-width: 2px; }
                            .fail-b, .fail-c { fill: #fff; }
                            .fail-b { font-size: 65px; font-family: Roboto-Regular; letter-spacing: -0.05em; }
                            .fail-d { filter: url(#a); }
                        </style>
                        <filter id="a" x="0" y="0" width="233.191" height="233.19" filterUnits="userSpaceOnUse">
                            <feOffset dy="2" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="6" result="b" />
                            <feFlood flood-opacity="0.282" />
                            <feComposite operator="in" in2="b" />
                            <feComposite in="SourceGraphic" />
                        </filter>
                    </defs>
                    <g transform="translate(-130.917 -217.367)">
                        <g class="fail-d" transform="matrix(1, 0, 0, 1, 130.92, 217.37)">
                            <path class="a"
                                d="M174.735,97.506l17.52-17.147a9.077,9.077,0,0,0-4.063-15.369L164.323,58.9l6.729-23.628a9.231,9.231,0,0,0-11.3-11.3L136.131,30.7l-6.09-23.878a9.141,9.141,0,0,0-15.364-4.065L97.536,20.41,80.4,2.754C75.583-2.332,66.661,0,65.031,6.819L58.941,30.7l-23.62-6.731a9.231,9.231,0,0,0-11.3,11.3L30.749,58.9,6.88,64.99A9.077,9.077,0,0,0,2.816,80.359l17.52,17.147L2.816,114.652a9.077,9.077,0,0,0,4.063,15.369l23.87,6.092L24.02,159.741a9.231,9.231,0,0,0,11.3,11.3l23.62-6.731,6.09,23.878a9.059,9.059,0,0,0,15.364,4.065l17.142-17.526,17.141,17.526a9.1,9.1,0,0,0,15.364-4.065l6.09-23.878,23.62,6.731a9.231,9.231,0,0,0,11.3-11.3l-6.729-23.628,23.87-6.092a9.077,9.077,0,0,0,4.063-15.369l-17.52-17.147Z"
                                transform="translate(19.06 17.06)" />
                        </g><text class="fail-b" [attr.transform]="'translate(' + (percent < 10 ? '205.54' : (percent < 100 ? '185.54' : '170.54')) + ' 380.82)'">
                            <tspan x="0" y="0" qa="score">{{percent}}%</tspan>
                        </text>
                        <g transform="translate(185.548 272.324)">
                            <g transform="translate(0 0)">
                                <path class="fail-c"
                                    d="M363.18,382.579l-2.548,5.872,15.911,6.9L375.5,397.76l-15.908-6.9-2.514,5.8-1.9-.821,6.1-14.075Z"
                                    transform="translate(-355.179 -348.435)" />
                                <path class="fail-c"
                                    d="M369.684,388.662l6.256,5.619-1.752,1.954-14.43-12.96,4.434-4.937a7.375,7.375,0,0,1,4.3-2.678,5.072,5.072,0,0,1,4.177,1.495,4.411,4.411,0,0,1,1.413,2.316,5.294,5.294,0,0,1-.212,2.866,3.99,3.99,0,0,1,2.791-.585,5.537,5.537,0,0,1,2.614,1.41l1.36,1.219a6.051,6.051,0,0,0,1.342.937,2.444,2.444,0,0,0,1.335.268l.239.214-1.809,2.013a2.19,2.19,0,0,1-1.438-.4,11.248,11.248,0,0,1-1.447-1.1l-1.317-1.185a3.508,3.508,0,0,0-2.5-1.008,3.26,3.26,0,0,0-2.361,1.192Zm-1.538-1.381,2.5-2.787a4.878,4.878,0,0,0,1.513-2.951,3.216,3.216,0,0,0-1.265-2.452,3.327,3.327,0,0,0-2.525-.99,4.2,4.2,0,0,0-2.65,1.606l-2.682,2.987Z"
                                    transform="translate(-349.34 -356.265)" />
                                <path class="fail-c"
                                    d="M377.723,381.812l-.528-11.076,2.6-1.495.369,14.3,3.4,5.917-2.266,1.3-3.5-6.09-11.984-7.393,2.6-1.495Z"
                                    transform="translate(-341.62 -364.393)" />
                                <path class="fail-c"
                                    d="M390.169,381.344l-7.291.439-1.431,5.094-2.673.161,5.819-19.794,2.248-.134,8.005,18.961-2.673.161Zm-6.663-1.8,5.785-.348-3.319-8.146-.082,0Z"
                                    transform="translate(-325.098 -367.11)" />
                                <path class="fail-c"
                                    d="M399.925,386.636a6.238,6.238,0,0,1-2.86,1.247,9.466,9.466,0,0,1-4.522-.348,7.483,7.483,0,0,1-4.732-3.667,7.83,7.83,0,0,1-.535-6.14l1.1-3.847a8.005,8.005,0,0,1,3.619-4.955,8.108,8.108,0,0,1,10.314,2.427,5.535,5.535,0,0,1,.548,4.725l-.046.068-2.423-.691a4.054,4.054,0,0,0-.294-3.153,4.31,4.31,0,0,0-2.8-2.027,4.4,4.4,0,0,0-3.906.616,6.46,6.46,0,0,0-2.468,3.69l-1.106,3.87a6.312,6.312,0,0,0,.212,4.491,4.645,4.645,0,0,0,3.08,2.618,6.816,6.816,0,0,0,2.743.321,3.947,3.947,0,0,0,1.734-.607l1.2-4.2-4.229-1.208.564-1.975,6.752,1.931Z"
                                    transform="translate(-314.744 -366.044)" />
                                <path class="fail-c"
                                    d="M405.333,386.778l-5.94-4.252-4.318,3.057-2.179-1.561,16.984-11.709,1.834,1.31L405.99,393.4l-2.179-1.558Zm-4.04-5.6,4.714,3.376,2.55-8.419-.064-.048Z"
                                    transform="translate(-307.094 -360.475)" />
                                <path class="fail-c" d="M400.822,391.566l-1.72-2,14.7-12.664,1.722,2Z"
                                    transform="translate(-299.182 -354.626)" />
                                <path class="fail-c"
                                    d="M407.46,402.465l-1.149-2.361,9.227-14.894-.059-.061-13.3,6.483-1.151-2.359,17.444-8.506,1.151,2.361-9.216,14.889.061.059,13.288-6.479,1.149,2.359Z"
                                    transform="translate(-296.725 -349.699)" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>

            <div class="text-block">
                <div class="caption" qa="summary">Summary</div>
                <div class="correct-a">
                    <svg width="8" height="6" viewBox="0 0 8 6">
                        <defs><style qa="correct-ans-style">.ra {fill: #2eaae1;}</style> </defs>
                        <path class="ra" d="M7.657,13.2l-1.9-1.9-.646.641,2.542,2.542,5.458-5.458-.641-.641Z" transform="translate(-5.11 -8.38)" />
                    </svg>
                    <span qa="correct-ans-text">Correct Answers:</span>&nbsp;<b qa="correct-ans-value">{{exam.session.correctAnswers || 0}}</b>
                </div>
                <div class="incorrect-a">
                    <svg width="7" height="7" viewBox="0 0 7 7">
                        <defs><style qa="incorrect-ans-style">.wa {fill: red;}</style></defs>
                        <path class="wa"
                            d="M-2083.465,15.243l-2.828,2.828-.708-.707,2.828-2.828L-2087,11.707l.708-.707,2.828,2.828,2.828-2.828.707.707-2.828,2.828,2.828,2.828-.707.707Z"
                            transform="translate(2087 -11)" />
                    </svg>
                    <span qa="incorrect-ans-text">Incorrect Answers: <b qa="incorrect-ans-value">{{25 - (exam.session.correctAnswers || 0)}}</b></span>
                </div>
                <div class="time" qa="time-text">Completion Time: <b qa="time-value">{{exam.session.elapsedMinutes || 1}} minutes</b></div>
            </div>
        </div>

    </div>
    <div class="pager">
        <exam-pager
            [items]="pagerItems"
            [resultView]="true"
            (select)="questionSummary($event)"></exam-pager>
    </div>

    <div class="what-next">
        <span class="title" qa="what-next">What Next?</span>
        <div *ngIf="passed" class="cong" qa="next-pass">
            <ol>
                <li *ngIf="percent < 100" qa="next-pass-text">Brush up on the skills from the questions that you missed. The following BlueHelp article covers the questions that caused you to stumble:
                    <ul class="articles">
                        <li *ngFor="let a of articles; let index = index" [attr.qa]="'article-' + (index + 1)"><a target="_blank" href='{{a.url}}' qa="link">{{a.name}}</a></li>
                    </ul>
                </li>
                <li *ngIf="nextExam" qa="study-next">Study and prepare for Exam {{nextExam?.examId}} : {{ExamHelper.getExamName(nextExam?.examId)}} at the <a target="_blank" href='https://support.theaiminstitute.com/' qa="study-next-link">BlueHelp Knowledge Center.</a></li>
            </ol>
            <div *ngIf="nextExam" qa="next-exam">If you would like to take the next exam now, <a [routerLink]="[ '/exam', nextExam?.examId, 'question', 1 ]" qa="next-exam-link">click here</a> to begin.</div>
            <div qa="next-done">If you’re all done for now, you can close this window.</div>
        </div>
        <div *ngIf="!passed" class="fail" qa="next-fail">
            <div qa="next-fail-text">In order to achieve certification, you need to score at least 80% on each exam. You can take another variation of this exam in 24 hours. In the meantime, please study the articles in the following BlueHelp area to help you succeed next time:</div>
            <ul class="articles">
                <li *ngFor="let a of articles; let index = index" [attr.qa]="'article-' + (index + 1)"><a target="_blank" href='{{a.url}}' qa="link">{{a.name}}</a></li>
            </ul>
            <div qa="close">When finished reviewing your summary, you can close this window.</div>
        </div>

    </div>
</div>
