<div class="boxes" [ngClass]="{'result-view': resultView}">
    <ng-container *ngFor="let i of items; let ind = index">
        <div class="column">
            <div [attr.class]="'box status-' + i.status + (i.selected ? ' selected' : '')"
                (click)="select.emit(i.index)"
                [attr.qa]="'page-' + (ind + 1)">
                <svg *ngIf="i.status == ExamAnswerStatus.Answered && (!resultView || i.status !== ExamAnswerStatus.InCorrect)"
                    width="8" height="69" viewBox="0 0 8 6" qa="state">
                    <path [attr.fill]="i.selected ? '#fff' : '#2eaae1'" d="M7.657,13.2l-1.9-1.9-.646.641,2.542,2.542,5.458-5.458-.641-.641Z" transform="translate(-5.11 -8.38)" />
                </svg>
                <svg *ngIf="resultView && (i.status === ExamAnswerStatus.InCorrect || i.status === ExamAnswerStatus.NotAnswered)" width="7" height="7" viewBox="0 0 7 7" qa="state">
                    <defs><style>.wa {fill: red;}</style></defs>
                    <path class="wa"
                        d="M-2083.465,15.243l-2.828,2.828-.708-.707,2.828-2.828L-2087,11.707l.708-.707,2.828,2.828,2.828-2.828.707.707-2.828,2.828,2.828,2.828-.707.707Z"
                        transform="translate(2087 -11)" />
                </svg>
            </div>
            <span class="number" qa="number">{{i.index}}</span>
        </div>
    </ng-container>
</div>
