<div class="dropdown-content" bp-clickoutside (clickOutside)="clickOutside.emit($event)">
    <h1>{{ name }}</h1>
    <h2>{{ email }}</h2>
    <hr>
    <a href="https://www.blueprintingcenter.com/" qa="contact" target="_blank" class="logout">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 24 24" xml:space="preserve">
            <path d="M0,20h19v-2.7H0V20z M0,4v2.7h24V4H0z M0,13.3h14v-2.7H0V13.3z"/>
        </svg>

        AIM Client Center
    </a>
    <a href="https://theaiminstitute.com/contact-us" qa="contact" target="_blank" class="logout">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 24 24" xml:space="preserve">
            <path d="M19.4,17.8c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v4c0,0.3-0.1,0.6-0.3,0.8C20,23.9,19.7,24,19.4,24
                c-5.3,0-9.9-1.9-13.7-5.7C1.9,14.5,0,10,0,4.6C0,4.3,0.1,4,0.3,3.8c0.2-0.2,0.5-0.3,0.8-0.3h4c0.3,0,0.6,0.1,0.8,0.3
                C6.1,4,6.2,4.3,6.2,4.6c0,1.4,0.2,2.8,0.6,4.1C7,9.1,6.9,9.5,6.6,9.9l-2.5,2.5c1.7,3.3,4.2,5.8,7.5,7.5l2.5-2.5
                c0.3-0.3,0.7-0.4,1.2-0.3C16.6,17.5,18,17.8,19.4,17.8z M21.3,12c0-2.6-0.9-4.8-2.7-6.6s-4-2.7-6.6-2.7V0c3.3,0,6.2,1.2,8.5,3.5
                C22.8,5.8,24,8.7,24,12H21.3z M16,12c0-1.1-0.4-2-1.2-2.8C14,8.4,13.1,8,12,8V5.3c1.8,0,3.4,0.7,4.7,2c1.3,1.3,2,2.9,2,4.7H16z"/>
        </svg>
        Contact Options
    </a>
    <a qa="logout" class="logout" (click)="logout.emit(email)">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 24 24" xml:space="preserve">
            <path d="M17.6,5.6l-1.8,1.8l3.3,3.3H6.8v2.5h12.3l-3.3,3.3l1.8,1.8L24,12L17.6,5.6z"/>
            <path d="M12.3,21.4H2.6V2.6h9.8V0H2.6C1.9,0,1.3,0.3,0.8,0.8C0.3,1.3,0,1.9,0,2.6v18.8c0,0.7,0.3,1.2,0.8,1.8
                C1.3,23.7,1.9,24,2.6,24h9.8V21.4z"/>
        </svg>
        Log out
    </a>
</div>
