<div>
    <router-outlet></router-outlet>
</div>
<aim-loading *ngIf="loading"></aim-loading>
<i class="fa fa-check" style="visibility: hidden"></i>
<exam-error-modal *ngIf="showOffline"
    [headerText]="'No Internet connection'"
    [bodyText]="'Looks like you are not connected to the internet, please check your connection and try again.'"
    [customBtnType]="ButtonType.Primary"
    [customBtnText]="'OK'"
    (action)="showOffline=false"
    (cancel)="showOffline=false"></exam-error-modal>
<exam-error-modal *ngIf="showSessionExpired"
    [headerText]="'Session Expired'"
    [bodyText]="'Your Log In session has expired, please click on the button to log back into the Practitioner Exam.'"
    [customBtnType]="ButtonType.Primary"
    [customBtnText]="'Log in to Client Center'"
    [hideClose]="true"
    (action)="gotoClientCenter()"
    (cancel)="showSessionExpired=false"></exam-error-modal>
