import {EventAggregatorService} from './event-aggregator.service';
import { Injectable } from '@angular/core';

@Injectable()
export class StateProviderService {
    constructor(private eventAggregatorService: EventAggregatorService) {
        this.eventAggregatorService.listen('logout').subscribe(() => this.clear());
    }

    private get storage(): Storage {
        return localStorage;
    }

    // @obsolete
    public clear() {
        localStorage.clear();
        sessionStorage.clear();
    }

    // @obsolete
    public set(key: string, data: any): void {
        this.storage.setItem(key, JSON.stringify(data));
    }

    // @obsolete
    public get(key: string, callback: (data: any) => void, error?: (e: string) => void): void {
        const json = this.storage.getItem(key);
        if (!json) {
            if (error) {
                error('not set');
            }
            return;
        }

        try {
            console.log(`reading ${key}`);
            const data = JSON.parse(json);
            if (data) {
                callback(data);
            }
        } catch (e) {
            console.error('Unable to read from Local Storage: ' + key);
            if (error) {
                error('unable to read');
            }
        }
    }

    public remove(key: string) {
        this.storage.removeItem(key);
    }

    public reset(): Promise<void> {
        try {
            this.storage.clear();
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public write<T>(key: string, data: T): Promise<void> {
        try {
            this.storage.setItem(key, JSON.stringify(data));
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    check(key: string): boolean {
        try {
            return this.storage.getItem(key) !== null;
        } catch (e) {
            console.error('Unable to read from Local Storage: ' + key);
            console.log(e);
            return false;
        }
    }

    private readJson(key: string): string | null {
        try {
            return this.storage.getItem(key);
        } catch (e) {
            console.error('Unable to read from Local Storage: ' + key);
            console.log(e);
            return null;
        }
    }

    public read<T>(key: string): Promise<T> {
        const json = this.readJson(key);
        if (!json) {
            return Promise.reject(null);
        }

        try {
            console.log(`reading ${key}`);
            const data = JSON.parse(json);
            return Promise.resolve(data);
        } catch (e) {
            console.error('Unable to parse from Local Storage: ' + key);
            console.log(e);
            return Promise.reject(e);
        }
    }
}
