import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ExamService } from 'app/exam/services';
import { AuthService, EventAggregatorService } from '../services';
import { OpenExamGuard } from './open-exam.guard';

@Injectable()
export class OpenFinishedExamGuard extends OpenExamGuard {
    constructor(router: Router,
        authService: AuthService,
        examService: ExamService,
        eas: EventAggregatorService) {
        super(router, authService, examService, eas);
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        console.log('[OpenFinishedExamGuard] canActivate');
        if (!await super.canActivate(route, state)) {
            return false;
        }

        const exam = this.examService.currentExam;
        if (exam.session.completedOn) {
            return true;
        } else {
            this.router.navigate(['exam', exam.id]);
            return false;
        }
    }
}
