export enum KeyCode {
    Escape = 27,
    Enter = 13,
    Tab = 9,
    Whitespace = 32,
    Dot = 190,
    DotNumPad = 110,
    Comma = 188,
    Backspace = 8,
    End = 35,
    Home = 36,
    Subtract = 109,
    ArrowUp = 38,
    ArrowRight = 39,
    ArrowDown = 40,
    ArrowLeft = 37,
    Delete = 46,
}
