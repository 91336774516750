<exam-header
    [secondStyle]="true"
    [titleAsLink]="true"
    [linkAction]="resultView ? 'Close' : ''"
    (linkClick)="close()"></exam-header>
<exam-info-header
    [examName]="examName"
    [timeRemaining]="timeRemaining"
    [examType]="ExamType.Practitioner"
    [examId]="exam.id"
    [completedOn]="exam.session.completedOn"></exam-info-header>


<div class="content" *ngIf="exam">
    <div class="panel">
        <div class="question" [attr.qid]="currentQuestion?.id" qa="question">{{currentSessionQuestion.index}}. {{currentQuestion?.text}}</div>
        <div class="answers" qa="answers">
            <ng-container *ngFor='let a of currentAnswers; let index=index'>
                <div class="answer" [attr.qa]="'answer' + (index + 1)">
                    <!-- <i *ngIf="testMode && a.correct" class="material-icons" qa="correct">check</i> -->
                    <i *ngIf="a.correct" class="material-icons" qa="correct">check</i>
                    <exam-answer
                        [resultView]="resultView"
                        [answer]="a"
                        [sessionAnswerId]="currentSessionQuestion.answerId"
                        [saving]="examService.saving"
                        [save$]="examService.saveAnswer$.asObservable()"
                        (select)="answer($event)"></exam-answer>
                </div>
            </ng-container>
        </div>
        <div class="buttons">
            <exam-button *ngIf="resultView"
                [type]="ButtonType.Transparent"
                [text]="'Back To Summary'"
                (click)="summary()"
                qa="nav-summary"></exam-button>
            <exam-button *ngIf="!resultView && currentSessionQuestion.index > 1"
                [type]="ButtonType.Transparent"
                [text]="'Previous'"
                [disabled]="examService.saving"
                (click)="prev()"
                qa="nav-prev"></exam-button>
            <exam-button  *ngIf="!resultView && currentSessionQuestion.index !== 25"
                [type]="ButtonType.RightArrow"
                [text]="'Next'"
                [disabled]="examService.saving"
                (click)="next()"
                qa="nav-next"></exam-button>
            <exam-button *ngIf="!resultView && currentSessionQuestion.index === 25"
                [type]="ButtonType.Primary"
                [text]="'Finish & View Results'"
                [disabled]="examService.saving"
                (click)="complete()"
                qa="nav-complete"></exam-button>
        </div>
        <div *ngIf="resultView" class="article">
            <span qa="article-label">Learn more about this topic in the <i>BlueHelp</i> article:</span>
            <ul>
                <li><a target="_blank" href='{{article.url}}' qa="article-link">{{article.name}}</a></li>
            </ul>
        </div>
    </div>
    <div class="pager">
        <exam-pager
            [items]="pagerItems"
            [resultView]="resultView"
            (select)="goto($event)"></exam-pager>
    </div>
</div>

<exam-error-modal *ngIf="showError"
    [headerText]="'We’re having trouble saving your answer.'"
    [bodyText]="'Please make sure you have a stable internet connection and try again. If the problem persists, close this window and continue the exam later.'"
    [cancelBtn]="true"
    [customBtnType]="ButtonType.Primary"
    [customBtnText]="'Try Again'"
    (cancel)="showError = false; examService.saveAnswer$.next(-1)"
    (action)="showError = false; examService.saveAnswer$.next(selectedAnswerId)"></exam-error-modal>

<exam-error-modal *ngIf="showWarning"
        [headerText]="'Time Expired'"
        [bodyText]="'You have reached the time limit. Click &quot;Go to Summary&quot; to view your score.'"
        [customBtnType]="ButtonType.Transparent"
        [customBtnText]="'Go to Summary'"
        [warning]="true"
        (cancel)="showWarning = false; router.navigate(['exam', exam.id, 'final'])"
        (action)="showWarning = false; router.navigate(['exam', exam.id, 'final'])"></exam-error-modal>
