import { Injectable } from '@angular/core';
import { CompleteExamSessionRequest, ExamElapsedSecondsRequest } from 'app/core/model';
import { ExamSessionRequest } from 'app/core/model/exam-session-request';
import { AuthService, BackEndService } from 'app/core/services';
import { ExamAnswerStatus, ExamStatus } from 'app/model';
import { Exam, ExamQuestionAnswer } from 'app/model/entities/exam';
import { ExamHelper } from 'app/model/entities/exam-helper';
import { ExamSessionQuestion } from 'app/model/entities/exam-session';
import { ExamInfo, ExamSummary } from 'app/model/entities/exam-summary';
import { ArrayEx } from 'app/shared/helpers';
import { Subject } from 'rxjs';


@Injectable()
export class ExamService {

    summaries: ExamSummary[];
    currentExam: Exam;
    saveAnswer$ = new Subject<number>();
    timeExpired: boolean;
    saving: boolean;

    get questionResultView(): boolean {
        return this.currentExam && this.currentExam.session.completedOn !== undefined;
    }

    constructor(private backEndService: BackEndService, private authService: AuthService) {


    }

    private getStatus(info: ExamInfo): ExamStatus {
        if (info.completedOn) {
            return (info.correctAnswers / 25) >= .80 ? ExamStatus.Passed : ExamStatus.NotPassed;
        }
        return !!info.startedOn ? ExamStatus.InProgress : ExamStatus.New;
    }

    private getScore(info: ExamInfo): ExamStatus {
        return info.completedOn ? Math.round((100 * info.correctAnswers / 25)) : 0;
    }

    async loadSummaries() {
        const list = await this.backEndService.loadExamSummaries();
        this.summaries = list.map(x => {
                const status = this.getStatus(x);
                return new ExamSummary(
                    x.examId,
                    status,
                    x.takenTimes,
                    x.correctAnswers,
                    x.totalAnswers,
                    this.getScore(x),
                    60 - Math.floor(x.elapsedSeconds / 60), // time remaining
                    status === ExamStatus.Passed ? Math.max(1, Math.floor(x.elapsedSeconds / 60)) : 60 , // timeToComplete
                    (status === ExamStatus.New || status === ExamStatus.NotPassed) ? 25 : (25 - x.correctAnswers), // numberOfQuestions
                    null,
                    x.completedOn,
                    x.startedOn,
                    status === ExamStatus.NotPassed && ExamHelper.retakenIn(x.completedOn) <= 0
                );
            });
    }

    async loadExam(examId: number, force = false) {
        console.log('[ExamService] load exam #' + examId);

        if (!force && this.currentExam && this.currentExam.id === examId) {
            console.log('[ExamService] Exam has been already loaded.');
            return;
        }
        this.currentExam = await this.backEndService.loadExam(<ExamSessionRequest> {
            userId: this.authService.userId,
            examId: Number(examId),
            level: this.authService.userLevel
        });
        this.currentExam.session.sessionQuestions = ArrayEx.orderBy(this.currentExam.session.sessionQuestions, 'index');

        // move answer 'All of the above.' to the last position
        this.currentExam.questions.forEach(q => {
            const index = ArrayEx.indexBy(q.answers, 'text', 'All of the above.');
            if (index !== -1) {
                q.answers.push(q.answers.splice(index, 1)[0]);
            }
        });
    }

    async saveAnswer(sessionId: number, questionId: number, answerId: number) {
        await this.backEndService.saveAnswer(<ExamSessionRequest> {
            examSessionId: sessionId,
            questionId: questionId,
            answerId: answerId
        });
    }

    async completeExam() {
        const exam = this.currentExam;
        const sessionId = exam.session.id;
        await this.backEndService.completeExam(<CompleteExamSessionRequest> {
            examSessionId: sessionId
        });
        // let's reload results from the back-end
        await this.loadExam(exam.id, true);
        exam.session.completedOn = new Date();
    }

    async examElapsed(elapsed: number) {
        const session = this.currentExam.session;
        const sessionId = session.id;
        session.elapsedSeconds += 5;
        await this.backEndService.examElapsed(<ExamElapsedSecondsRequest> {
            examSessionId: sessionId,
            elapsedSeconds: elapsed
        });
    }

    async getExam(examId: number): Promise<ExamSummary|undefined>  {
        // TODO: do not load summaries extra time
        if (!this.summaries) {
            await this.loadSummaries();
        }
        return this.summaries.find(x => x.examId === examId);
    }

    getQuestion(index: any): ExamSessionQuestion {
        const questions = this.currentExam.session.sessionQuestions;
        const i = +index;
        if (!i) {
            const question = questions.find(x => !x.answerId);
            if (question) {
                return question;
            }
            index = 25;
        }

        return questions[Math.max(0, Math.min(25, i || 0) - 1)];
    }

    geAnswerStatus(resultView: boolean, a?: ExamQuestionAnswer): ExamAnswerStatus {
        if (a) {
            if (resultView) {
                return a.correct ? ExamAnswerStatus.Answered : ExamAnswerStatus.InCorrect;
            } else {
                return ExamAnswerStatus.Answered;
            }
        } else {
            return ExamAnswerStatus.NotAnswered;
        }
    }

}
