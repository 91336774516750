import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-sso-login-ui',
    templateUrl: './sso-login-ui.component.html',
    styleUrls: ['./sso-login-ui.component.scss']
})
export class SsoLoginUiComponent {
    @Input() error = '';
    @Input() signingIn = false;
    @Input() sessionExpiredMode = false;

    @Output() openHome = new EventEmitter();
}
