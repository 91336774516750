import {AuthService} from '../services';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) {
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        console.log('[AuthenticatedGuard] canActivate');
        if (this.authService.userName && !this.authService.sessionExpired) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
