import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'exam-home-header',
    templateUrl: './exam-home-header.component.html',
    styleUrls: ['./exam-home-header.component.scss']
})
export class ExamHomeHeaderComponent implements OnInit {

    @Input() blueBelt: boolean | null;
    constructor() { }

    ngOnInit(): void {
    }

    openHelp() {
        window.open('https://support.theaiminstitute.com/', '_blank');
    }

}
