import { Component, Input, OnInit } from '@angular/core';
import { ExamStatus } from 'app/model';
import { ExamHelper } from 'app/model/entities/exam-helper';
import { ExamSummary } from 'app/model/entities/exam-summary';

@Component({
    selector: 'exam-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

    @Input() summary: ExamSummary;
    @Input() blueBelt: boolean ;
    ExamStatus =  ExamStatus;
    ExamHelper = ExamHelper;

    get examAction(): string {
        switch (this.summary.status) {
            case ExamStatus.Loading:
                return 'Open Exam';
            case ExamStatus.InProgress:
                return 'Continue Exam';
            case ExamStatus.NotPassed:
                return this.summary.canBeRetaken ? 'Retake Exam' : `Retake In <br>${ExamHelper.retakenIn(this.summary.completedOn)} Hour(s)`;
            case ExamStatus.New:
                return 'Begin Exam';
            default:
                return '';
        }
    }

    get dateText(): string {
        switch (this.summary.status) {
            case ExamStatus.Loading:
                return 'Loading details...';
            case ExamStatus.InProgress:
                return `Last started on `;
            case ExamStatus.NotPassed:
                return `May be retaken `;

            case ExamStatus.Passed:
                return `Completed on `;
            default:
                return 'Never taken';
        }
    }

    get date(): Date | null {
        switch (this.summary.status) {
            case ExamStatus.InProgress:
                return this.summary.lastStartedOn;
            case ExamStatus.NotPassed:
                const d = this.summary.completedOn;
                if (!d) {
                    return null;
                } else {
                    const date = new Date(d);
                    date.setDate(d.getDate() + 7);
                    return date;
                }
            case ExamStatus.Passed:
                return this.summary.completedOn;
            default:
                return null;
        }
    }

    constructor() { }

    ngOnInit(): void {

    }



}
