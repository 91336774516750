import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import * as Sentry from '@sentry/angular';

import { AppComponent } from './app.component';
import * as httpInterceptors from './core/interceptors';
import { appRoutes } from './app.routes';

import { CoreModule } from './core';
import { SharedModule } from './shared';

import { ToastrModule  } from 'ngx-toastr';
import { ExamModule } from './exam/exam.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-top-center'
        }),
        CoreModule,
        SharedModule,
        ExamModule
    ],
    providers: [
        Title,
        { provide: HTTP_INTERCEPTORS, useClass: httpInterceptors.LoggingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: httpInterceptors.AuthInterceptor, multi: true },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
