<div class="main">
    <div class="header">
        <exam-home-header [blueBelt]="auth.blueBelt"></exam-home-header>
    </div>
    <div class="body">
        <div class="description" qa="description">
            <div class="title" qa="title">To Achieve {{auth.blueBelt ? 'Blue Belt' : ''}} Certification…</div>
            <div *ngIf="!auth.blueBelt" class="text" qa="intro-text">
                You must complete 12 <a target="_blank" href="https://www.blueprintingcenter.com/blueprinting/elearning">e-learning modules</a> (#13-24), participate in 3 Discovery & 2 Preference interviews, complete a Blueprinting project Market Case, and <b>pass these 4 exams</b>:
            </div>
            <div *ngIf="auth.blueBelt" class="text" qa="intro-text">
                You must complete all <a target="_blank" href="https://www.blueprintingcenter.com/blueprinting/elearning">e-learning modules</a>, moderate or be note-taker in 5 Discovery & 3 Preference interviews, and <b>pass these 7 exams</b>:
            </div>
        </div>
        <div class="summaries" qa="exams">
            <div *ngIf="summaries" class="container">
                <exam-summary [summary]="summaries[0]" [blueBelt]="auth.blueBelt" (click)="openExam(0)" qa="exam-ms"></exam-summary>
                <exam-summary [summary]="summaries[1]" [blueBelt]="auth.blueBelt" (click)="openExam(1)" qa="exam-dp"></exam-summary>
                <exam-summary [summary]="summaries[2]" [blueBelt]="auth.blueBelt" (click)="openExam(2)" qa="exam-di"></exam-summary>
                <exam-summary [summary]="summaries[3]" [blueBelt]="auth.blueBelt" (click)="openExam(3)" qa="exam-pi"></exam-summary>
                <exam-summary *ngIf="auth.blueBelt" [summary]="summaries[4]" (click)="openExam(4)" qa="exam-bb1"></exam-summary>
                <exam-summary *ngIf="auth.blueBelt" [summary]="summaries[5]" (click)="openExam(5)" qa="exam-bb2"></exam-summary>
                <exam-summary *ngIf="auth.blueBelt" [summary]="summaries[6]" (click)="openExam(6)" qa="exam-bb3"></exam-summary>
            </div>
        </div>
        <div class="rules">
            <div class="title" qa="rules">Rules and Tips</div>
            <ol class="list">
                <li class="item" qa="rules-l1">Each exam has 25 questions. Each question has just one correct answer. A minimum score of 80% must be achieved for a passing grade.</li>
                <li class="item" qa="rules-l2">100% of the exam questions are covered in your <a class="link" target="_blank" href='https://support.theaiminstitute.com'>BlueHelp® Knowledge Center</a>, so if you want to take this exam with high confidence, read through the appropriate <i>BlueHelp</i> articles beforehand. Note: Exams 2 and 3 (Discovery Preparation and Discovery Interviews) are both covered in a single BlueHelp section called “Discovery Interviews (Step 2).”</li>
                <li class="item" qa="rules-l3">You have 60 minutes to take each of the <ng-container *ngIf="!auth.blueBelt">4</ng-container><ng-container *ngIf="auth.blueBelt">7</ng-container> exams, which should be more than enough time… even with some breaks or interruptions. If you get stuck on a question or two, you should even have time to refer to your <i>BlueHelp Knowledge Center</i> for help.</li>
                <li class="item" qa="rules-l4">If your browser crashes mid-way, just come back to this page and continue the exam: All the questions you’ve answered up to the point of the crash should have been retained.</li>
                <li class="item" qa="rules-l5">You can take these {{auth.blueBelt ? '7': '4'}} exams in any order you choose.</li>
                <li class="item" qa="rules-l6">After the exam, you’ll see your score and some suggested <i>BlueHelp</i> articles to read for those topics you answered incorrectly.</li>
                <li class="item" qa="rules-l7">If you pass an exam, it will automatically be recorded on your <a class="link" target="_blank" href='https://www.blueprintingcenter.com/'>Blueprinting Center</a> Home page (after you refresh the page).</li>
                <li class="item" qa="rules-l8">If you don’t pass an exam, you can take another version of the same exam after 24 hours. Expect some repeated questions as well as new ones.</li>
            </ol>
        </div>
    </div>

    <div class="footer">
        © 2020&nbsp; <a qa="f-aim" target="_blank" href='https://theaiminstitute.com'>The AIM
            Institute</a>&nbsp;<b>|</b>&nbsp;<a qa="f-cc" target="_blank" href='https://www.blueprintingcenter.com/'>Blueprinting Center</a>&nbsp;<b>|</b>&nbsp;<a qa="f-help"
            target="_blank" href='https://support.theaiminstitute.com/'>BlueHelp</a>
    </div>

</div>
