import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, EventAggregatorService } from 'app/core/services';
import { ExamService } from 'app/exam/services/exam-service.service';
import { ExamStatus } from 'app/model';
import { ExamSummary } from 'app/model/entities/exam-summary';

@Component({
    selector: 'exam-home',
    templateUrl: './exam-home.component.html',
    styleUrls: ['./exam-home.component.scss']
})
export class ExamHomeComponent implements OnInit {

    summaries: ExamSummary[] = [];

    constructor(private examService: ExamService, private eas: EventAggregatorService, private router: Router, public auth: AuthService) { }

    async ngOnInit() {
        const count = this.auth.userLevel === 1 ? 7 : 4;
        for (let i = 1; i <= count; i++) {
            this.summaries.push(new ExamSummary(i, ExamStatus.Loading, 0, 0, 0, 0));
        }
        await this.init();
    }

    async openExam(n: number) {
        const summary = this.summaries[n];
        switch (summary.status) {
            case ExamStatus.Passed:
            case ExamStatus.NotPassed:
                await this.router.navigate(summary.canBeRetaken
                    ? ['exam', summary.examId, 'question', 1]
                    : ['exam', summary.examId, 'final']);
                break;
            case ExamStatus.Loading:
            case ExamStatus.New:
            case ExamStatus.InProgress:
                await this.router.navigate(['exam', summary.examId]);
                break;
        }
    }

    private async init() {
        try {
            await this.examService.loadSummaries();
            this.summaries = this.examService.summaries;
            console.log(this.summaries);
        } catch (e) {
            this.eas.publish('offline');
        }
    }

}
