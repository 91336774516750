import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ExamService } from 'app/exam/services';
import { AuthService, EventAggregatorService } from '../services';

@Injectable()
export class OpenExamGuard implements CanActivate {
    constructor(protected router: Router,
        protected authService: AuthService,
        protected examService: ExamService,
        protected eas: EventAggregatorService) {
    }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        console.log('[OpenExamGuard] canActivate');
        let valid = true;
        const examId = +route.params['id'];

        if (examId) {
            if (this.authService.blueBelt) {
                valid = examId >= 1 && examId <= 7;
            } else {
                valid = examId >= 1 && examId <= 4;
            }
        }

        if (valid) {
            valid = await this.load(examId);
        }

        if (!valid) {
            this.router.navigate(['intro']);
        }

        return valid;
    }

    private async load(examId: number): Promise<boolean> {
        try {
            console.log('[OpenExamGuard] load exam #' + examId);
            await this.examService.loadExam(examId);
            console.log('[OpenExamGuard] loaded exam #' + examId);
            return true
        } catch (e) {
            console.warn('Unable to load exam', e);
            this.eas.publish('offline');
            return false;
        }
    }
}
