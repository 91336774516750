<div class="container" [ngClass]="{style2: secondStyle}">

    <a [attr.class]="'title ' + (titleAsLink ? 'link' : '')" [routerLink]="titleAsLink ? ['/intro'] : null">
        <div class="logo" qa="logo"></div>
        <div class="text">CERTIFICATION CENTER</div>
    </a>

    <div class="right-container">
        <div *ngIf="linkAction" class="action" (click)="linkClick.emit()" qa="link-action">{{linkAction}}</div>
        <div *ngIf="authService.userName && !authService.sessionExpired" class="user">
            <div class="avatar" (mousedown)="toggleProfile($event)">
                <aim-avatar [email]="authService.userName" qa="email"></aim-avatar>
                <i class="material-icons" qa="user-menu">{{ showUserMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
            </div>

            <aim-profile-menu
                *ngIf="showUserMenu"
                [name]="authService.fullName"
                [email]="authService.userName"
                (logout)="logout()"
                (clickOutside)="toggleProfile($event)"
            ></aim-profile-menu>
        </div>
    </div>
</div>
