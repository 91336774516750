<!-- <div *ngIf='authService.userName && authService.sessionExpired' class="expired fade-in-top" (click)='init()' title="Click here to re-enter your password"> -->
<div *ngIf='authService.userName && authService.sessionExpired' class="expired fade-in-top">
    Session Expired
    <p>Please Login again</p>
    <!-- <p>Click here to re-enter your password</p> -->
</div>

<div *ngIf="ssoUrl" class="bp-dialog-wrapper">
    <div class="dialog sso-dialog">
        <a (click)="ssoUrl = ''" class="fa fa-times fa-2x close-popup di-popup-close"></a>
        <iframe [src]="ssoUrl">
        </iframe>
    </div>
</div>
