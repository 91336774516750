import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'exam-error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['../error-404/error-404.component.scss']
})
export class Error403Component implements OnInit, OnDestroy {
  private sub: any;
  public message: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(p => {
      this.message = p['message'];
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
