import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ExamType } from 'app/model';
import { DomHelper } from 'app/shared/helpers';

@Component({
    selector: 'exam-info-header',
    templateUrl: './info-header.component.html',
    styleUrls: ['./info-header.component.scss']
})
export class InfoHeaderComponent implements OnInit, AfterViewInit {

    @Input() examId: number;
    @Input() examName: number;
    @Input() timeRemaining?: number;
    @Input() completedOn?: Date;
    @Input() examType: ExamType;
    @ViewChild('svg') svg: ElementRef;
    svgRect2: Element|null;
    svgRect4: Element|null;
    svgRect5: Element|null;
    ExamType = ExamType;


    constructor() { }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.responsiveSvg();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        this.svgRect2 = DomHelper.getByClassName('.r-2', this.svg.nativeElement);
        this.svgRect4 = DomHelper.getByClassName('.r-4', this.svg.nativeElement);
        this.svgRect5 = DomHelper.getByClassName('.r-5', this.svg.nativeElement);
        this.responsiveSvg();
    }

    private responsiveSvg() {
        const w = window.innerWidth;
        if (w < 716) {
            this.svg.nativeElement.setAttribute('width', w + 'px');
            this.svgRect2?.setAttribute('transform', 'translate(' + (749 - (716 - w))  + ' 45)');
            this.svgRect4?.setAttribute('transform', 'translate(' + (854 - (716 - w))  + ' 73)');
            this.svgRect5?.setAttribute('transform', 'translate(' + (837 - (716 - w))  + ' 85)');
        }
    }
}
