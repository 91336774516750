import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/services';

@Component({
    selector: 'exam-license-expired',
    templateUrl: './license-expired.component.html',
    styleUrls: ['./license-expired.component.scss']
})
export class LicenseExpiredComponent implements OnInit {
    public checking: boolean;

    constructor(public authService: AuthService,
        // public messageService: MessageService,
        ) { }

    ngOnInit() {
    }

    public async refresh() {
        this.checking = true;
        try {
            // await this.profileService.load();
            // this.messageService.success('License updated!');
        } catch (e) {
            // this.messageService.error('License is Expired. Please contact support.');
        }
        this.checking = false;
    }
}
