import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'app/core/services';
import { ExamService } from 'app/exam/services/exam-service.service';
import { ExamType } from 'app/model';
import { Exam, ExamArticle, ExamQuestionAnswer } from 'app/model/entities/exam';
import { ExamHelper } from 'app/model/entities/exam-helper';
import { ExamSummary } from 'app/model/entities/exam-summary';
import { PagerItem } from 'app/shared/models/pager-model';

@Component({
    selector: 'exam-final',
    templateUrl: './exam-final.component.html',
    styleUrls: ['./exam-final.component.scss']
})
export class ExamFinalComponent implements OnInit {

    @Input() exam: Exam;
    nextExam?: ExamSummary;
    examName: string;
    sessionAnswers: ExamQuestionAnswer[];
    articles: ExamArticle[] = [];
    pagerItems: PagerItem[] = [];
    ExamType = ExamType;
    ExamHelper = ExamHelper;

    get passed(): boolean {
        return this.exam.session.correctAnswers !== undefined && (this.exam.session.correctAnswers * 100 / 25) >= 80;
    }

    get percent(): number {
        return this.exam.session.correctAnswers !== undefined ? this.exam.session.correctAnswers * 100 / 25 : 0;
    }

    constructor(public examService: ExamService, private route: ActivatedRoute, private router: Router, private auth: AuthService) { }

    async ngOnInit() {
        this.exam = this.examService.currentExam;
        this.examName = ExamHelper.getExamName(this.exam.id, this.auth.blueBelt);
        this.initQuestions();
        this.nextExam = await this.examService.getExam(this.exam.id + 1);
        this.pagerItems = this.initPager();
    }

    async questionSummary(index: number) {
        await this.router.navigate(['exam', this.exam.id, 'question', index]);
    }

    async close() {
        await this.router.navigate(['intro']);
    }

    private initQuestions() {
        const e = this.exam;
        const session = e.session.sessionQuestions;
        const answers = e.questions.map(x => x.answers).reduce((a, b) => a.concat(b), []);
        const articles: ExamArticle[] = [];
        e.questions
            .filter(x => session.find(q => x.id === q.questionId && (!q.answerId // not answered at all
                    || answers.find(a => a.id === q.answerId && !a.correct))) // wrong asnwer
            )
            .map(x => x.article)
            .forEach(a => {
                if (!articles.find(x => x.url === a.url)) { // get distinct values
                    articles.push(a);
                }
            });
        articles.sort((a, b) => a.name === b.name ? 0 : (a.name < b.name ? -1 : 1));
        this.articles = articles;
        this.sessionAnswers = answers;
    }

    private initPager(): PagerItem[] {
        const items: PagerItem[] = [];
        this.exam.session.sessionQuestions.forEach(q => {
            const answer = this.sessionAnswers.find(a => a.id === q.answerId);
            items.push(<PagerItem>{
                index: q.index,
                status: this.examService.geAnswerStatus(true, answer)
            });
        });
        return items;
    }

}
