import { Routes } from '@angular/router';
import * as core from './core/components';
import { examRoutes } from './exam/exam.routes';

export const appRoutes: Routes = [
    ...examRoutes,

    { path: 'sso-login/:token', component: core.SsoLoginComponent },
    { path: '', redirectTo: 'intro', pathMatch: 'full' },
    { path: '404', component: core.Error404Component },
    { path: '403/:message', component: core.Error403Component },

    { path: '**', component: core.Error404Component }
];
