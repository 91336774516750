import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BackEndService } from 'app/core/services';

@Component({
  selector: 'exam-login',
  templateUrl: './exam-login.component.html',
  styleUrls: ['./exam-login.component.scss']
})
export class ExamLoginComponent implements OnInit {

    url = '';
    error = false;

    constructor(private backEndService: BackEndService, public authService: AuthService, private router: Router) { }

    async ngOnInit() {
        if (this.authService.userId) {
            this.router.navigate(['/']);
        } else {
            await this.init();
        }
    }

    async init() {
        try {
            this.url = await this.backEndService.getSsoUrl();
            this.error = false;
        } catch (e) {
            this.error = true;
            console.warn(e);
            setTimeout(() => this.init(), 1000);
        }
    }

    async login() {
        location.href = this.url;
    }
}
