import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const started = Date.now();
        return next.handle(req).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    const elapsed = Date.now() - started;
                    console.log(
                        `[LoggingInterceptor] Request for ${
                            req.url
                        } took ${elapsed} ms.`
                    );
                }
                return event;
            })
        );
    }
}
