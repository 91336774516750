import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared';
import { CoreModule } from 'app/core';

import * as c from './components';
import * as s from './services';
import * as p from './pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    SharedModule,
    CoreModule
  ],
  declarations: [
    c.ExamHomeComponent,
    c.ExamSessionComponent,
    c.ExamFinalComponent,
    c.ExamLoginComponent,
    c.AnswerComponent,

    p.AddDotPipe
  ],
  providers: [
      s.ExamService
  ]

})
export class ExamModule { }
