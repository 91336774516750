export enum ExamAnswerStatus {
    Answered = 1,
    InCorrect = 2,
    Current = 3,
    NotAnswered = 4
}

export enum ExamStatus {
    Loading = 0,
    New = 1,
    InProgress = 2,
    NotPassed = 3,
    Passed = 4
}

export enum ExamType {
    Practitioner = 1,
    BlueBelt = 2
}


export enum TeamType {
    Core = 0,
    Support = 1
}

export enum TaskStatus {
    Open = 0,
    Done = 100
}

export enum AssumptionType {
    MarketDynamics = 1,
    InternalCapability = 2,
    RequiredOutcome = 3,
    DesiredOutcome = 4,
    RequiredSolution = 5,
    DesiredSolution = 6,
}

export enum ImpactRating {
    NotSet,
    Irrelevant,
    Helpful,
    Important,
    Crucial,
    Essential
}

export enum CertaintyRating {
    NotSet,
    Certain,
    Confident,
    Probably,
    Perhaps,
    Unknown
}

export enum LandmineRating {
    NotSet,
    Possible,
    Probable,
    Confirmed
}

export enum CertificationLevel {
    None,
    Practitioner,
    BlueBelt
}
