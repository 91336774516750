import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit {
    @Input() email = '-';

    constructor() { }

    ngOnInit() {
    }

}
