import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExamAnswerStatus } from 'app/model';
import { PagerItem } from 'app/shared/models/pager-model';

@Component({
    selector: 'exam-pager',
    templateUrl: './pager.component.html',
    styleUrls: ['./pager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagerComponent implements OnInit {

    @Input() items: PagerItem[];
    @Input() resultView: boolean;
    @Output() select = new EventEmitter<number>();
    ExamAnswerStatus = ExamAnswerStatus;

    constructor() { }

    ngOnInit(): void {


    }
}
