import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from "@sentry/tracing";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const host = location.host;
let env = '';
if (host.indexOf('exam.blueprintingcenter.com') !== -1) {
    env = 'production';
} else {
    switch (host) {
        case 'aim-exam-qa.vaynersystems.com':
            env = 'qa';
            break;
        case 'aim-exam-stage.vaynersystems.com':
            env = 'stage';
            break;
        case 'aim-exam-uat.vaynersystems.com':
            env = 'uat';
            break;
    }
}

if (env) {
    console.log(`Init Sentry. Host: ${host}. Env: ${env}.`);
    Sentry.init({
        dsn: "https://e49e637ce7e24ef59ce1b1b90b01e7b8@o530098.ingest.sentry.io/5797500",
        release: (<any>window).version || '1.0.0',
        environment: env,
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: [host, "https://" + host + "/"],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
        ],

        beforeSend: async (event: Sentry.Event, hint?: Sentry.EventHint) => {
            const e = hint?.originalException as any;
            if (e) {
                if (e.rejection?.status === 401) {
                    return null;
                }
            }
            return event;
        },

        tracesSampleRate: 0.1,
    });
}

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => {

    }).catch(err => {
        console.error(err);
    });
