import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService, BackEndService, EventAggregatorService } from 'app/core/services';

@Component({
    selector: 'exam-session-expired',
    templateUrl: './session-expired.component.html',
    styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {
    working = false;
    showLoginDialog = false;
    ssoUrl: SafeResourceUrl = '';
    password: string;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public authService: AuthService,
        private backEndService: BackEndService,
        private sanitizer: DomSanitizer,
        private eventAggregatorService: EventAggregatorService
    ) {}

    ngOnInit() {
        /* window.addEventListener('message', e => this.onMessage(e), false);
        this.eventAggregatorService.listen('login').subscribe(() => this.changeDetectorRef.markForCheck());
        this.eventAggregatorService.listen('reEnterPassword').subscribe(() => this.init()); */

        this.eventAggregatorService.listen('sessionExpired').subscribe(() => {
            setTimeout(() => {
                this.authService.sessionExpired = false;
                this.authService.reset();
            }, 5000);
        });
    }

    private onMessage(event: any) {
        if (event.data === 'bp-session-renewed' || event.message === 'bp-session-renewed') {
            this.authService.reloadToken();
            this.ssoUrl = '';
            this.changeDetectorRef.markForCheck();
        }
    }

    async init() {

        /* if (!this.ssoUrl) {
            const url = await this.backEndService.getSsoUrl() + '&user_email=' + encodeURIComponent(this.authService.userName);
            this.ssoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } */
    }
}
