export class DomHelper {

    static closest(node: Element, css?: string, nodeName?: string): Element | null {

        let parent = <Element>node.parentNode;
        let found = false;
        while (parent) {
          if ((css && parent.classList && parent.classList.contains(css))
            || (nodeName && parent.nodeName === nodeName.toUpperCase()) ) {
                found = true;
                break;
          } else {
            parent = <Element>parent.parentNode;
          }
        }
        return found ? parent : null;
    }

    static focusByClassName(cssClass: string, element: HTMLElement) {
        if (element) {
            const items = element.getElementsByClassName(cssClass);
            if (items.length) {
                const input = <HTMLElement> items[0];
                if (input) {
                    input.focus();
                }
            }
        }
    }

    static focusById(id: string): any {
        const input = <HTMLElement> document.getElementById(id);
        if (input) {
            input.focus();
        }
    }

    static getByClassName(cls: string, el: any): Element|null {
        return (<HTMLElement>el).querySelector(cls);
    }

}
