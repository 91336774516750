import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent {
    @Input() title = 'Loading...';
}
