export class ArrayEx {
    static selectMany(arr: any[], navProp: string): any[] {
        return arr.map(i => i[navProp]).reduce((a, b) => a.concat(b));
    }
    static groupBy (arr: any[], key: string): any {
        return (arr || []).reduce((acc, x = {}) => ({
          ...acc,
          [x[key]]: [...acc[x[key]] || [], x]
        }), {})
    }

    static orderBy (arr: any[], key: string): any[] {
        return arr.sort((first, second) => first[key] - second[key]);
    }

    static sum (list: any[]): number {
        return !list.length ? 0 : list.reduce((a, b) => { return Number(a) + Number(b); });
    };

    static sumBy (list: any[], prop: string): number {
        return list.reduce((a, b) => {
            return Number(a) + Number(b[prop])
        }, 0);
    };

    static maxBy (list: any[], prop: string): number {
        let max = list.length > 0 ? Number(list[0][prop]) : 0;
        list.forEach(i => {
            if (Number(i[prop]) > max) {
                max = Number(i[prop]);
            }
        });
        return max;
    };

    static minBy (list: any[], prop: string): number {
        let min = list.length > 0 ? Number(list[0][prop]) : 0;
        list.forEach(i => {
            if (Number(i[prop]) < min) {
                min = Number(i[prop]);
            }
        });
        return min;
    };

    static lastBy (list: any[], condition: (i: any) => any): any {
        const filtered = list.filter(condition);
        return filtered.length > 0 ? filtered[filtered.length - 1] : null;
    };

    static firstBy (list: any[], condition: (i: any) => any): any {
        const filtered = list.filter(condition);
        return filtered.length > 0 ? filtered[0] : null;
    };

    static unique(list: any[]): boolean {
        return list.filter((v, i, a) => a.indexOf(v) === i).length === list.length;
    }

    static distinct(list: any[]): any[] {
        if (typeof(list[0]) === 'string') {
            return Array.from(new Set(list));
        }
        if (list[0] instanceof Date) {
            return Array.from(new Set(list.map((i: Date) => i.toDateString()))).map(i => new Date(i));
        }
        return [];
    }

    static chunks(list: any[], count: number): Array<any[]> {
        const chunks: Array<any[]> = [];
        let chunk: any[] = list.filter((v, i) => i <= count - 1);
        let index = count - 1;
        if (chunk.length) {
            chunks.push(chunk);
        }

        while (chunk.length) {
            chunk = list.filter((v, i) => i > index && i <= (index + count));
            if (chunk.length) {
                chunks.push(chunk);
            }
            index += count;
        }
        return chunks;
    }

    static indexBy(list: any[], prop: any, search: string): number {
        const item = list.find(x => x[prop] === search);
        if (item) {
            return list.indexOf(item);
        }
        return -1;
    }
}
