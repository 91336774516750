import { Routes } from '@angular/router';
import * as g from 'app/core/guards';

import * as c from './components';

export const examRoutes: Routes = [{
        path: 'login', component: c.ExamLoginComponent
    }, {
        path: 'intro', component: c.ExamHomeComponent,
        canActivate: [ g.AuthenticatedGuard ],
        // resolve: {r: RouteResolver}
    }, {
        path: 'exam/:id',
        canActivate: [ g.AuthenticatedGuard ],
        children: [{
            path: 'question/:n', component: c.ExamSessionComponent,
            canActivate: [ g.OpenExamGuard ],
        }, {
            path: 'final', component: c.ExamFinalComponent,
            canActivate: [ g.OpenFinishedExamGuard ],
        }, {
            path: '', component: c.ExamSessionComponent,
            canActivate: [ g.OpenExamGuard ],
        }]
    }];
