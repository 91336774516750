<div [attr.class]="'main type-' + status + (resultView ? ' readonly' : '') + (this.answer.correct ? ' correct' : '')"
    (click)="save()">
    <div class="check" qa="check">
        <div *ngIf="status === AnswerStatus.NotSelected">
            <span class="material-icons" qa="unchecked">radio_button_unchecked</span>
        </div>
        <i *ngIf="status === AnswerStatus.Selected" class="material-icons selected" qa="checked">check_circle_outline</i>
        <i *ngIf="status === AnswerStatus.InCorrect" class="material-icons incorrect" qa="wrong">cancel</i>
        <div *ngIf="status === AnswerStatus.Active" class="active" qa="active">
            <svg x="0px" y="0px" width="25px" height="25px" viewBox="3 3 45 45">
                <path fill="#26B4E4"
                    d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                    <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25"
                        dur="0.6s" repeatCount="indefinite" />
                </path>
            </svg>
        </div>
    </div>
    <div class="text" qa="answer-text">{{answer.text | addDot}}</div>
</div>
