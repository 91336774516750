<div [attr.class]="'main type-' + examId">
    <svg width="716" height="103" #svg>
        <defs><style>.ih-a {fill: #fff;opacity: 0.15; }</style></defs>
        <g transform="translate(-162 -45)">
            <rect class="ih-a r-1" width="48" height="48" rx="6" transform="translate(179 81)" />
            <rect class="ih-a r-2" width="60" height="60" rx="8" transform="translate(749 45)" />
            <rect class="ih-a r-3" width="48" height="48" rx="6" transform="translate(162 100)" />
            <rect class="ih-a r-4" width="24" height="24" rx="4" transform="translate(854 73)" />
            <rect class="ih-a r-5" width="24" height="24" rx="4" transform="translate(837 85)" />
        </g>
    </svg>
    <div class="caption">
        <div class="exam" qa="exam-header">
            <span class="number">{{examType == ExamType.Practitioner ? 'Practitioner' : 'Blue Belt'}} Exam {{examId}}:</span>
            <span class="name"> {{examName}}</span>
        </div>
        <div class="info" qa="exam-info">
            <div *ngIf="timeRemaining" class="time" qa="time">{{timeRemaining}} minute{{timeRemaining > 1 ? 's' : ''}} remaining</div>
            <div *ngIf="!timeRemaining && completedOn" class="date" qa="completed-on">Completed on {{completedOn | date:'MMMM d, yyyy'}}</div>
        </div>
    </div>
</div>
