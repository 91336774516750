import { Component, OnInit, EventEmitter, Output, Input, HostListener, HostBinding, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { KeyCode } from 'app/core/key-code';


@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {
    @Input() icon = '';
    @Input() text = '';
    @Input() iconRight = '';

    // @Output() click = new EventEmitter();

    @HostBinding('class.disabled') @Input() disabled = false;
    @HostBinding('class.primary') @Input() primary = true;
    @HostBinding('class.transparent') @Input() transparent = false;

    @HostBinding('class.iconMode')
    get iconMode() {
        return !this.text;
    }

    constructor(private elementRef: ElementRef) { }

    ngOnInit() {
    }

    @HostListener('keypress', ['$event'])
    onkeypress(e: KeyboardEvent) {
        if (e.keyCode === KeyCode.Enter || e.keyCode === KeyCode.Whitespace) {

            e.preventDefault();
            e.stopPropagation();
            this.elementRef.nativeElement.click();
        }
    }

    // @HostListener('click', ['$event.target'])
    // onClick() {
    //     this.click.emit(this);
    // }

}
