import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {

    @Input()
    @HostBinding('style.width.px')
    @HostBinding('style.height.px')
    size = 25;

    @Input()
    @HostBinding('style.borderWidth.px')
    thickness = 2;

    @Input()
    @HostBinding('style.borderTopColor')
    color = '#4f6aa7';

    @Input() opacity = '.1';

    @HostBinding('style.borderBottomColor')
    @HostBinding('style.borderLeftColor')
    @HostBinding('style.borderRightColor')
    secondColor = '';

    ngOnInit(): void {
        const c = this.hexToRgb(this.color);
        this.secondColor =
            'rgba(' + c.r + ',' + c.g + ',' + c.b + ', ' + this.opacity + ')';
    }

    private hexToRgb(hex: string) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (!result) {
            throw Error('[SpinnerComponent] Invalid hex value ' + hex)
        }
        return {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16)
              };
    }

}
