<div [attr.class]="'main exam-' + summary.examId">
    <div class="title">
        <svg class="svg" width="196" height="82" viewBox="0 0 196 82">
            <defs><style>.a {fill: #fff;opacity: 0.15;}</style></defs>
            <g transform="translate(-12 -9)">
                <rect class="a" width="43" height="43" rx="8" transform="translate(38 18)" />
                <rect class="a" width="22" height="22" rx="4" transform="translate(69 69)" />
                <rect class="a" width="21" height="21" rx="4" transform="translate(187 9)" />
                <rect class="a" width="21" height="21" rx="4" transform="translate(172 20)" />
            </g>
        </svg>
        <div class="number" qa="id">Exam {{summary.examId}}</div>
        <div class="name" qa="name">{{ExamHelper.getExamName(this.summary.examId, blueBelt)}}</div>
    </div>
    <div class="body">
        <div class="top">
            <div *ngIf="summary.status !== ExamStatus.Loading && summary.status !== ExamStatus.New" class="taken-times" qa="taken">Taken {{summary.takenTimes}} time{{summary.takenTimes > 1 ? 's' : ''}}.</div>
            <div class="date" [ngClass]="{cut: summary.status == ExamStatus.Passed}" qa="date">
                <ng-container *ngIf="summary.status == ExamStatus.NotPassed">
                    <span *ngIf="!summary.canBeRetaken" [innerHtml]="examAction"></span>
                    <span *ngIf="summary.canBeRetaken">&nbsp;</span>
                </ng-container>
                <ng-container *ngIf="summary.status != ExamStatus.NotPassed">
                    {{dateText}}{{date | date: 'MMM d, yyyy'}}.
                </ng-container>
            </div>
            <div *ngIf="summary.status !== ExamStatus.NotPassed || summary.canBeRetaken" [ngClass]="{link: summary.status !== ExamStatus.NotPassed || summary.canBeRetaken, 'disabled-link': summary.status === ExamStatus.NotPassed && !summary.canBeRetaken}" qa="action">{{examAction}}</div>
            <svg *ngIf="summary.status == ExamStatus.Passed" class="passed" width="131.53" height="131.53" viewBox="0 0 131.53 131.53" qa="exam-state">
                <defs>
                    <style>
                        .pa { fill: #dc3637; stroke: #fff; }
                        .pb, .pc { fill: #fff; }
                        .pb { font-size: 31px; font-family: Roboto-Regular; letter-spacing: -0.05em; }
                        .pd { filter: url(#a); }
                    </style>
                    <filter id="a" x="0" y="0" width="121.53" height="121.53" filterUnits="userSpaceOnUse">
                        <feOffset dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="6" result="b" />
                        <feFlood flood-opacity="0.282" />
                        <feComposite operator="in" in2="b" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <g transform="translate(18.526 16.527)">
                    <g class="pd" transform="matrix(1, 0, 0, 1, -18.53, -16.53)">
                        <path class="pa"
                            d="M75.678,42.23,83.266,34.8a3.931,3.931,0,0,0-1.76-6.656L71.168,25.508l2.914-10.233a4,4,0,0,0-4.894-4.9l-10.23,2.915L56.321,2.953a3.959,3.959,0,0,0-6.654-1.76L42.243,8.84,34.819,1.193a3.959,3.959,0,0,0-6.654,1.76L25.528,13.295,15.3,10.379a4,4,0,0,0-4.894,4.9l2.914,10.233L2.98,28.147A3.931,3.931,0,0,0,1.22,34.8L8.808,42.23,1.22,49.656a3.931,3.931,0,0,0,1.76,6.656l10.338,2.639L10.4,69.184a4,4,0,0,0,4.894,4.9l10.23-2.915,2.638,10.341a3.923,3.923,0,0,0,6.654,1.76l7.424-7.591,7.424,7.591a3.94,3.94,0,0,0,6.654-1.76l2.638-10.341,10.23,2.915a4,4,0,0,0,4.894-4.9L71.168,58.951l10.338-2.639a3.931,3.931,0,0,0,1.76-6.656L75.678,42.23Z"
                            transform="translate(18.53 16.53)" />
                    </g><text class="pb" [attr.transform]="'translate(' + (summary.score < 10 ? '25.406' : (summary.score < 100 ? '15.406' : '5.406')) + ' 57.524)'">
                        <tspan x="0" y="0" qa="score">{{summary.score}}%</tspan>
                    </text>
                    <g transform="translate(21.354 16.91)">
                        <path class="pc"
                            d="M364.5,380.03l2.173,2.129-.717.733-5.419-5.307,2.03-2.073a2.673,2.673,0,0,1,1.767-.911,2.351,2.351,0,0,1,2.356,2.307,2.692,2.692,0,0,1-.877,1.783Zm-.573-.561,1.313-1.34a1.377,1.377,0,0,0,.029-2.115,1.509,1.509,0,0,0-1.02-.462,1.475,1.475,0,0,0-1.1.525l-1.312,1.34Z"
                            transform="translate(-360.542 -368.048)" />
                        <path class="pc"
                            d="M373.341,374.928l-2.5,1.372.346,2.038-.918.5-1.246-7.964.772-.422,5.992,5.363-.917.5Zm-2.656.476,1.987-1.089-2.533-2.324-.027.014Z"
                            transform="translate(-361.484 -367.587)" />
                        <path class="pc"
                            d="M380.79,372.813a1.145,1.145,0,0,0-.515-.788,3.1,3.1,0,0,0-1.387-.358,4.32,4.32,0,0,1-1.924-.516,1.766,1.766,0,0,1-.883-1.266,1.872,1.872,0,0,1,.428-1.584,2.707,2.707,0,0,1,1.682-.9,2.647,2.647,0,0,1,2,.337,2.059,2.059,0,0,1,.956,1.442l-.011.033-.964.172a1.4,1.4,0,0,0-.63-.973,1.617,1.617,0,0,0-1.209-.214,1.578,1.578,0,0,0-1.024.535,1.164,1.164,0,0,0-.219.959,1.008,1.008,0,0,0,.556.74,3.859,3.859,0,0,0,1.443.335,3.87,3.87,0,0,1,1.87.557,1.906,1.906,0,0,1,.838,1.3,1.77,1.77,0,0,1-.453,1.577,2.99,2.99,0,0,1-1.757.876,3.2,3.2,0,0,1-2.017-.267,1.873,1.873,0,0,1-1.09-1.479l0-.033.964-.17a1.276,1.276,0,0,0,.725.99,2.05,2.05,0,0,0,1.273.169,1.842,1.842,0,0,0,1.079-.524A1.067,1.067,0,0,0,380.79,372.813Z"
                            transform="translate(-362.265 -367.242)" />
                        <path class="pc"
                            d="M388.21,373.219a1.142,1.142,0,0,0-.244-.908,3.083,3.083,0,0,0-1.205-.772,4.334,4.334,0,0,1-1.667-1.092,1.768,1.768,0,0,1-.444-1.478,1.871,1.871,0,0,1,.9-1.371,2.7,2.7,0,0,1,1.878-.332,2.646,2.646,0,0,1,1.794.944,2.053,2.053,0,0,1,.458,1.668l-.02.028-.969-.139a1.4,1.4,0,0,0-.3-1.121,1.624,1.624,0,0,0-1.083-.581,1.583,1.583,0,0,0-1.14.19,1.165,1.165,0,0,0-.507.843,1.009,1.009,0,0,0,.3.876,3.872,3.872,0,0,0,1.266.768,3.854,3.854,0,0,1,1.6,1.114,1.9,1.9,0,0,1,.392,1.5,1.772,1.772,0,0,1-.924,1.356,2.987,2.987,0,0,1-1.943.285,3.2,3.2,0,0,1-1.833-.883,1.873,1.873,0,0,1-.574-1.745l.015-.029.969.139a1.274,1.274,0,0,0,.38,1.167,2.055,2.055,0,0,0,1.156.557,1.847,1.847,0,0,0,1.188-.161A1.066,1.066,0,0,0,388.21,373.219Z"
                            transform="translate(-363.14 -367.229)" />
                        <path class="pc"
                            d="M395.817,374.439l-2.808-1.348-1.174,2.446,3.286,1.578-.346.724-4.212-2.021,3.282-6.837,4.16,2-.349.727-3.236-1.552-1.061,2.212,2.808,1.348Z"
                            transform="translate(-363.878 -367.423)" />
                        <path class="pc"
                            d="M395.962,378.7l5.274-5.451,1.714,1.66a3.119,3.119,0,0,1,.087,4.643l-.721.745a3.11,3.11,0,0,1-4.639.062Zm5.449-4.156-4.154,4.293.977.945a2.091,2.091,0,0,0,1.674.656,2.6,2.6,0,0,0,1.671-.855l.728-.752a2.581,2.581,0,0,0,.791-1.691,2.09,2.09,0,0,0-.71-1.652Z"
                            transform="translate(-364.478 -367.898)" />
                    </g>
                </g>
            </svg>
            <svg *ngIf="summary.status == ExamStatus.NotPassed" class="try-again" width="130.96" height="130.96" viewBox="0 0 130.96 130.96" qa="exam-state">
                <defs>
                    <style>
                        .ta, .tb { fill: #fff; }
                        .ta { font-size: 30px;  font-family: Roboto-Regular; letter-spacing: -0.05em; }
                        .tc { filter: url(#a); }
                    </style>
                    <filter id="a" x="0" y="0" width="130.96" height="130.96" filterUnits="userSpaceOnUse">
                        <feOffset dy="2" input="SourceAlpha" />
                        <feGaussianBlur stdDeviation="6" result="b" />
                        <feFlood flood-opacity="0.282" />
                        <feComposite operator="in" in2="b" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <g transform="translate(-131.977 -218.428)">
                    <g class="tc" transform="matrix(1, 0, 0, 1, 131.98, 218.43)">
                        <path
                            d="M76.107,42.469,83.738,35a3.953,3.953,0,0,0-1.77-6.694l-10.4-2.653L74.5,15.362a4.021,4.021,0,0,0-4.922-4.924L59.292,13.37,56.64,2.97A3.982,3.982,0,0,0,49.948,1.2L42.482,8.89,35.016,1.2a3.982,3.982,0,0,0-6.692,1.77l-2.652,10.4L15.384,10.438a4.02,4.02,0,0,0-4.922,4.924l2.931,10.291L3,28.307A3.953,3.953,0,0,0,1.227,35l7.631,7.468L1.227,49.937A3.953,3.953,0,0,0,3,56.631l10.4,2.653L10.462,69.576A4.021,4.021,0,0,0,15.384,74.5l10.288-2.932,2.652,10.4a3.946,3.946,0,0,0,6.692,1.77L42.482,76.1l7.466,7.634a3.962,3.962,0,0,0,6.692-1.77l2.652-10.4L69.58,74.5A4.02,4.02,0,0,0,74.5,69.576L71.571,59.285l10.4-2.653a3.954,3.954,0,0,0,1.77-6.694l-7.631-7.468Z"
                            transform="translate(18 16)" />
                    </g><text class="ta" [attr.transform]="'translate(' + (summary.score < 10 ? '175.47' : (summary.score < 100 ? '165.47' : '155.47')) + ' 297.445)'">
                        <tspan x="0" y="0" qa="score">{{summary.score}}%</tspan>
                    </text>
                    <g transform="translate(165.47 250.934)">
                        <g transform="translate(0 0)">
                            <path class="tb"
                                d="M358.664,382.116l-1.11,2.557,6.93,3.005-.456,1.049-6.929-3.006L356,388.246l-.825-.358,2.658-6.13Z"
                                transform="translate(-355.179 -367.244)" />
                            <path class="tb"
                                d="M364.082,381.3l2.725,2.447-.763.851-6.285-5.645,1.931-2.15a3.212,3.212,0,0,1,1.875-1.166,2.209,2.209,0,0,1,1.819.651A1.922,1.922,0,0,1,366,377.3a2.306,2.306,0,0,1-.092,1.249,1.738,1.738,0,0,1,1.216-.255,2.412,2.412,0,0,1,1.138.614l.592.531a2.636,2.636,0,0,0,.585.408,1.064,1.064,0,0,0,.582.117l.1.093-.788.877a.954.954,0,0,1-.626-.172,4.9,4.9,0,0,1-.63-.48l-.574-.516a1.528,1.528,0,0,0-1.089-.439,1.42,1.42,0,0,0-1.028.519Zm-.67-.6,1.09-1.214a2.125,2.125,0,0,0,.659-1.285,1.4,1.4,0,0,0-.551-1.068,1.449,1.449,0,0,0-1.1-.431,1.831,1.831,0,0,0-1.154.7l-1.168,1.3Z"
                                transform="translate(-355.221 -367.188)" />
                            <path class="tb"
                                d="M371,374.716l-.23-4.824,1.133-.651.161,6.229,1.481,2.577-.987.568-1.525-2.652-5.22-3.22,1.133-.651Z"
                                transform="translate(-355.276 -367.129)" />
                            <path class="tb"
                                d="M383.737,373.31l-3.176.191-.623,2.219-1.164.07,2.535-8.621.979-.058,3.487,8.259-1.164.07Zm-2.9-.785,2.52-.152-1.446-3.548-.036,0Z"
                                transform="translate(-355.395 -367.11)" />
                            <path class="tb"
                                d="M392.57,376.087a2.717,2.717,0,0,1-1.245.543,4.124,4.124,0,0,1-1.97-.152,3.259,3.259,0,0,1-2.061-1.6,3.41,3.41,0,0,1-.233-2.674l.479-1.676a3.487,3.487,0,0,1,1.576-2.158,3.531,3.531,0,0,1,4.492,1.057,2.411,2.411,0,0,1,.239,2.058l-.02.03-1.055-.3a1.766,1.766,0,0,0-.128-1.373,1.877,1.877,0,0,0-1.22-.883,1.917,1.917,0,0,0-1.7.268,2.813,2.813,0,0,0-1.075,1.607l-.482,1.685a2.749,2.749,0,0,0,.092,1.956,2.023,2.023,0,0,0,1.342,1.14,2.968,2.968,0,0,0,1.195.14,1.719,1.719,0,0,0,.755-.265l.523-1.831-1.842-.526.246-.86,2.941.841Z"
                                transform="translate(-355.469 -367.118)" />
                            <path class="tb"
                                d="M398.313,378.614l-2.587-1.852-1.881,1.332-.949-.68,7.4-5.1.8.571L398.6,381.5l-.949-.679Zm-1.76-2.438,2.053,1.47,1.111-3.667-.028-.021Z"
                                transform="translate(-355.524 -367.158)" />
                            <path class="tb" d="M399.851,383.289l-.749-.871,6.4-5.516.75.872Z"
                                transform="translate(-355.581 -367.2)" />
                            <path class="tb"
                                d="M403.83,390.218l-.5-1.029,4.019-6.487-.026-.027L401.53,385.5l-.5-1.027,7.6-3.7.5,1.028-4.014,6.485.027.026,5.788-2.822.5,1.028Z"
                                transform="translate(-355.599 -367.235)" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div class="bottom">
            <div class="questions">
                <div class="count">{{summary.status == ExamStatus.Passed ? summary.correctAnswers : (summary.status == ExamStatus.InProgress ? (25 - summary.totalAnswers) : 25)}} Questions</div>
                <div class="text" qa="questions-text">{{summary.status == ExamStatus.Passed ? 'Correct' : 'To answer'}}</div>
            </div>
            <div *ngIf="summary.status != ExamStatus.Loading" class="time">
                <div class="min">{{summary.status == ExamStatus.InProgress ? summary.timeRemaining : summary.timeToComplete}} min</div>
                <div class="text" qa="time-text">{{summary.status == ExamStatus.InProgress ? 'Remaining' : 'To complete'}}</div>
            </div>
        </div>
    </div>
</div>
