import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventAggregatorService, AuthService } from '../../services';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'aim-sso-login',
    templateUrl: './sso-login.component.html',
    styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {
    sessionExpiredMode = false;
    working = false;
    error = '';

    constructor(private route: ActivatedRoute,
        private eventAggregatorService: EventAggregatorService,
        private router: Router,
        private authService: AuthService) {}

    async ngOnInit() {
        this.sessionExpiredMode = !!window.frameElement;
        const p = this.route.snapshot.params;
        const token = p['token'];
        if (!token) {
            this.error = 'Token is invalid';
        } else {
            this.ssoLogin(token);
        }
    }

    async ssoLogin(token: string) {
        this.working = true;
        try {
            await this.authService.ssoLogin(token);
            if (this.sessionExpiredMode) { // we are in iframe which means session expired
                parent.postMessage('bp-session-renewed', '*');
            } else {
                this.eventAggregatorService.publish('init');
                this.router.navigate(['/']);
            }
        } catch (e) {
            console.warn(e);
            if (e.status === 400 && e.error) {
                this.error = e.error.message;
            } else {
                this.error = e.message ? e.message : 'Unable to validate token. Please contact support.';
            }
            this.working = false;
        }
    }

    openHome() {
        this.router.navigate(['/']);
    }
}
