import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import * as c from './components';
import * as s from './services';
import * as g from './guards';

@NgModule({
    imports: [CommonModule, RouterModule, FormsModule],
    declarations: [
        c.LicenseExpiredComponent,
        c.Error403Component,
        c.Error404Component,
        c.SessionExpiredComponent,
        c.LoginComponent,
        c.LoadingComponent,
        c.ProfileMenuComponent,
        c.ButtonComponent,
        c.SpinnerComponent,
        c.AvatarComponent,
        c.SsoLoginComponent,
        c.SsoLoginUiComponent,
    ],
    exports: [
        c.LicenseExpiredComponent,
        c.SessionExpiredComponent,
        c.LoginComponent,
        c.LoadingComponent,
        c.ProfileMenuComponent,
        c.ButtonComponent,
        c.SpinnerComponent,
        c.AvatarComponent,
        c.SsoLoginComponent
    ],
    providers: [
        g.AuthenticatedGuard,
        g.OpenExamGuard,
        g.OpenFinishedExamGuard,
        s.AuthService,
        s.BackEndService,
        s.EventAggregatorService,
        s.StateProviderService,

    ]
})
export class CoreModule {}
