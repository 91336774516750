import { Component, Input, OnInit } from '@angular/core';
import { ButtonType } from 'app/shared/models/button-type.model';

@Component({
    selector: 'exam-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

    @Input()  type: ButtonType;
    @Input() text: string;
    @Input() disabled: boolean;
    ButtonType = ButtonType;
    constructor() { }

    ngOnInit(): void {
    }

}
