<div class="container">
    <div class="header">
        <i class="fa fa-exclamation-triangle header-icon" [ngClass]="{warning: warning}"></i>
        <div class="text" qa="error-header">{{headerText}}</div>
        <i *ngIf="!hideClose" class="material-icons close" (click)="cancel.emit()">clear</i>
    </div>
    <div class="body">
        <div class="text" qa="error-body">{{bodyText}}</div>
        <div class="actions">
            <exam-button *ngIf="cancelBtn" [type]="ButtonType.Transparent" [text]="'Cancel'" (click)="cancel.emit()" qa="cancel"></exam-button>
            <exam-button *ngIf="customBtnText" [type]="customBtnType" [text]="customBtnText" (click)="action.emit()" qa="action"></exam-button>
        </div>
    </div>
</div>
