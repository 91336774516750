import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, EventAggregatorService } from 'app/core/services';
import { ExamService } from 'app/exam/services/exam-service.service';
import { ExamType } from 'app/model';
import { Exam, ExamArticle, ExamQuestion, ExamQuestionAnswer } from 'app/model/entities/exam';
import { ExamHelper } from 'app/model/entities/exam-helper';
import { ExamSessionQuestion } from 'app/model/entities/exam-session';
import { ArrayEx } from 'app/shared/helpers';
import { ButtonType } from 'app/shared/models/button-type.model';
import { PagerItem } from 'app/shared/models/pager-model';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'exam-session',
    templateUrl: './exam-session.component.html',
    styleUrls: ['./exam-session.component.scss']
})
export class ExamSessionComponent implements OnInit, OnChanges, OnDestroy {

    @Input() exam: Exam;
    currentSessionQuestion: ExamSessionQuestion;
    currentQuestion?: ExamQuestion;
    currentAnswers: ExamQuestionAnswer[];
    sessionAnswers: ExamQuestionAnswer[];
    pagerItems: PagerItem[] = [];
    examName: string;
    elapsed: number;
    timeRemaining?: number;
    resultView: boolean;
    ExamType = ExamType;
    ButtonType = ButtonType;
    sub$?: Subscription;
    sub?: Subscription;
    showError: boolean;
    showWarning: boolean;
    selectedAnswerId: number;
    article: ExamArticle;
    testMode = false;

    private completed = false;

    constructor(public examService: ExamService,
        private eas: EventAggregatorService,
        private route: ActivatedRoute, public router: Router, private auth: AuthService) {

    }

    async ngOnInit() {
        this.sub = this.route.params.subscribe(async p => {
            const index = this.route.snapshot.params['n'];
            await this.init(index);
            await this.initTimer();
        });

    }

    private async init(index: string) {
        this.testMode = (<any> window).qa;
        this.exam = this.examService.currentExam;
        this.examName = ExamHelper.getExamName(this.exam.id, this.auth.blueBelt);

        this.currentSessionQuestion = this.examService.getQuestion(index);
        const actualIndex = this.currentSessionQuestion.index;
        if (+index !== actualIndex) {
            this.router.navigate(['exam', this.exam.id, 'question', actualIndex], { replaceUrl: true });
            return;
        }

        this.initQuestion(this.currentSessionQuestion.questionId);
        this.sessionAnswers = ArrayEx.selectMany(this.exam.questions, 'answers');
        this.pagerItems = this.initPager();

        this.resultView = this.exam.session.completedOn !== undefined;

        if (this.resultView) {
            const q = this.exam.questions.find(i => i.id === this.currentSessionQuestion.questionId);
            if (q) {
                this.article = q.article;
            }
        }
    }

    async ngOnChanges() {

    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
            this.sub = undefined;
        }
        if (this.sub$) {
            this.sub$.unsubscribe();
            this.sub$ = undefined;
        }
    }

    async initTimer() {
        this.completed = false;
        if (!this.examService.questionResultView && !this.sub$) {
            this.timeRemaining = this.exam.session.timeRemaining;

            this.sub$ = interval(5000).subscribe(async () => {
                if (this.timeRemaining === undefined || this.timeRemaining > 0) {
                    await this.examService.examElapsed(5);
                    if (this.exam.session.elapsedMinutes > 0) {
                        this.timeRemaining = this.exam.session.timeRemaining;
                    }
                } else if (!this.completed) {
                    try {
                        await this.examService.completeExam();
                        this.completed = true;
                    } catch (e) {
                        if (e.status === 400) { // already completed
                            this.completed = true;
                        } else {
                            console.error(e);
                        }
                    }
                    this.showWarning = true;
                }
            });
        }
    }

    @HostListener('document:keydown', ['$event'])
    keydown(e: KeyboardEvent) {
        if (e.key === 'ArrowRight') {
            this.next();
            e.preventDefault();
        } else if (e.key === 'ArrowLeft') {
            this.prev();
            e.preventDefault();
        }
    }
    next() {
        if (!this.examService.saving && this.currentSessionQuestion.index < 25) {
            this.router.navigate(['exam', this.exam.id, 'question', this.currentSessionQuestion.index + 1]);
        }
    }

    prev() {
        if (!this.examService.saving && this.currentSessionQuestion.index > 1) {
            this.router.navigate(['exam', this.exam.id, 'question', this.currentSessionQuestion.index - 1]);
        }
    }

    async answer(id: number) {
        this.selectedAnswerId = id;
        this.examService.saving = true;
        await this.examService
            .saveAnswer(this.exam.session.id, this.currentSessionQuestion.questionId, id)
            .then(() => this.currentSessionQuestion.answerId = id, () => {
                this.currentSessionQuestion.answerId = 0;
                this.showError = true;
            });
        this.examService.saving = false;
        this.pagerItems = this.initPager();
    }

    async complete() {
        try {
            if (!this.examService.saving) {
                this.examService.saving = true;
                await this.examService.completeExam();
                this.examService.saving = false;
            } else {
                return;
            }
        } catch (e) {
            this.eas.publish('offline');
            console.warn(e);
            this.examService.saving = false;
            return;
        }

        await this.router.navigate(['exam', this.exam.id, 'final']);
    }

    async summary() {
        await this.router.navigate(['exam', this.exam.id, 'final']);
    }

    async goto(index: number) {
        this.router.navigate(['exam', this.exam.id, 'question', index]);
    }

    async close() {
        await this.router.navigate(['intro']);
    }

    private initQuestion(id: number) {
        this.currentQuestion = this.exam.questions.find(x => x.id  === id);
        if (this.currentQuestion) {
            this.currentAnswers = this.currentQuestion.answers;
        }
    }

    private initPager(): PagerItem[] {
        const items: PagerItem[] = [];
        this.exam.session.sessionQuestions.forEach(q => {
            const answer = this.sessionAnswers.find(a => a.id === q.answerId);
            items.push(<PagerItem>{
                index: q.index,
                status: this.examService.geAnswerStatus(this.examService.questionResultView, answer),
                selected: q.questionId === this.currentQuestion?.id
            });
        });
        return items;
    }
}
