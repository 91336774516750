import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { BackEndService, EventAggregatorService } from './core/services';
import { ButtonType } from './shared/models/button-type.model';

@Component({
    selector: 'exam-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

    loading: boolean;
    showOffline: boolean;
    showSessionExpired: boolean;
    ButtonType = ButtonType;

    constructor(private cdr: ChangeDetectorRef, public eas: EventAggregatorService, private backEndService: BackEndService) {

    }

    ngOnInit() {
        this.eas.listen('loading').subscribe(v => {
            this.loading = v;
            this.cdr.detectChanges();
        });
        this.eas.listen('offline').subscribe(async v => {
            this.showOffline = true;
        });
        this.eas.listen('sessionExpired').subscribe(async v => {
            this.showSessionExpired = true;
        });
    }

    ngOnDestroy() {

    }

    ngAfterViewInit () {

    }

    async gotoClientCenter() {
        location.href = await this.backEndService.getSsoUrl();
    }
}
